import { Routes as RouterRoutes, Route } from "react-router-dom";

import { Box } from "@mui/material";

import { Layout } from "Layout";

import { ExpiredDisabledRoute } from "components/ExpiredDisabledRoute";
import { LoginRoute } from "components/LoginRoute";
import { PrivateRoute } from "components/PrivateRoute";
import { SyncPermission } from "components/SyncPage/SyncPermission";
import { TestError } from "components/TestError";

import { About } from "views/About";
import { AccountInformation } from "views/AccountInformation/AccountInformation";
import { AdjustmentList } from "views/Adjustments/AdjustmentList";
import { BuildList } from "views/Builds/BuildList";
import { Calendar } from "views/Calendar/Calendar";
import { ClassList } from "views/Classes/ClassList";
import { CompanyChooser } from "views/CompanyChooser";
import { PlusList } from "views/Configuration/PlusList";
import { CONFIGURATION_NAVIGATION } from "views/Configuration/navigationStructure";
import { ConfigurationLayout } from "views/ConfigurationLayout";
import { ContactUs } from "views/ContactUs/ContactUs";
import { CustomerList } from "views/Customers/CustomerList";
import { Dashboard } from "views/Dashboard/Dashboard";
import { DeletedItemList } from "views/DeletedItem/DeletedItemList";
import { DepartmentList } from "views/Departments/DepartmentList";
import { Disabled } from "views/Disabled/Disabled";
import { EstimateList } from "views/Estimates/EstimateList";
import { Expired } from "views/Expired/Expired";
import { FsPaymentList } from "views/FsPayments/FsPaymentList";
import { Home } from "views/Home";
import { InvoiceList } from "views/Invoices/InvoiceList";
import { ItemReceiptList } from "views/ItemReceipts/ItemReceiptList";
import { ItemList } from "views/Items/ItemList";
import { JobList } from "views/Jobs/JobList";
import { Login } from "views/Login";
import { LotList } from "views/Lots/LotList";
import { Mobile } from "views/Mobile/Mobile";
import { NotificationList } from "views/Notifications/List/NotificationList";
import { PaymentList } from "views/Payments/PaymentList";
import { PickTicketList } from "views/PickTickets/PickTicketList";
import { ProcessList } from "views/Processes/ProcessList";
import { PurchaseOrderList } from "views/PurchaseOrder/PurchaseOrderList";
import { RentalReturnList } from "views/RentalReturns/RentalReturnList";
import { RentalList } from "views/Rentals/RentalList";
import { ReorderList } from "views/Reorder/ReorderList";
import { CustomReport } from "views/Reports/CustomReport";
import { Home as ReportHome } from "views/Reports/Home";
import { StandardReport } from "views/Reports/StandardReport";
import { REPORTS } from "views/Reports/reportConfig";
import { ReturnList } from "views/Returns/ReturnList";
import { ReturnToVendorList } from "views/ReturnsToVendor/ReturnToVendorList";
import { RmaList } from "views/Rmas/RmaList";
import { SalesOrderList } from "views/SalesOrders/SalesOrderList";
import { SalesReceiptList } from "views/SalesReceipts/SalesReceiptList";
import { Search } from "views/Search/Search";
import { SerialList } from "views/Serials/SerialList";
import { ShipmentList } from "views/Shipments/ShipmentList";
import { SosPaymentsContact } from "views/SosPayments/Contact";
import { WhySosPayments } from "views/SosPayments/WhySosPayments";
import { SyncItemList } from "views/SyncItem/SyncItemList";
import { TaskList } from "views/Tasks/TaskList";
import { ThirdPartyAuth } from "views/ThirdPartyAuth";
import { TransferList } from "views/Transfers/TransferList";
import { UpsShipmentList } from "views/UpsShipments/UpsShipmentList";
import { UserVoice } from "views/UserVoice/UserVoice";
import { VendorList } from "views/Vendors/VendorList";
import { WhatsNew } from "views/WhatsNew/WhatsNew";
import { WorkOrderList } from "views/WorkOrders/WorkOrderList";
import { DbReset } from "views/test/DbReset";

import { NotFound } from "NotFound";

const objectList = [
  ["adjustment", AdjustmentList],
  ["build", BuildList],
  ["bins", PlusList],
  ["class", ClassList],
  ["customer", CustomerList],
  ["department", DepartmentList],
  ["deleteditem", DeletedItemList],
  ["estimate", EstimateList],
  ["fspayment", FsPaymentList],
  ["invoice", InvoiceList],
  ["item", ItemList],
  ["itemreceipt", ItemReceiptList],
  ["job", JobList],
  ["lot", LotList],
  ["payment", PaymentList],
  ["pickticket", PickTicketList],
  ["process", ProcessList],
  ["purchaseorder", PurchaseOrderList],
  ["rental", RentalList],
  ["rentalreturn", RentalReturnList],
  ["reorder", ReorderList],
  ["return", ReturnList],
  ["returntovendor", ReturnToVendorList],
  ["rma", RmaList],
  ["salesorder", SalesOrderList],
  ["salesreceipt", SalesReceiptList],
  ["serial", SerialList],
  ["shipment", ShipmentList],
  ["syncitem", SyncItemList],
  ["task", TaskList],
  ["transfer", TransferList],
  ["upsshipment", UpsShipmentList],
  ["vendor", VendorList],
  ["workorder", WorkOrderList],
];

const syncList = [
  "account",
  "bill",
  "creditmemo",
  "employee",
  "journalentry",
  "purchase",
  "taxcode",
  "syncerror",
];

export function Routes() {
  return (
    <Box sx={{ gridArea: "main", display: "flex", position: "relative" }}>
      <Layout pageTitle="SOS Inventory">
        <RouterRoutes>
          <Route element={<LoginRoute />}>
            <Route path="/login" element={<Login />} />
            <Route path="/login/auth" element={<ThirdPartyAuth />} />
          </Route>
          <Route element={<ExpiredDisabledRoute />}>
            <Route path="/expired" element={<Expired />} />
            <Route path="/disabled" element={<Disabled />} />
            <Route path="/choosecompany" element={<CompanyChooser />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/home/mobile" element={<Mobile />} />
            <Route path="/help/uservoice" element={<UserVoice />} />
            <Route path="/help/contact" element={<ContactUs />} />
            <Route path="/help/whatsnew" element={<WhatsNew />} />
            <Route path="/sospayments" element={<WhySosPayments />} />
            <Route path="/calendar" element={<Calendar />} />
            <Route
              path="/accountInformation"
              element={<AccountInformation />}
            />
            <Route
              path="/sospaymentscontact"
              element={<SosPaymentsContact />}
            />
            <Route path="/search" element={<Search />} />
            <Route path="/report" element={<ReportHome />} />
            {Object.keys(REPORTS).map((report) => (
              <Route
                key={report}
                path={`/report/${report}`}
                element={<StandardReport reportType={report} />}
              />
            ))}
            <Route path="/customreport/:id" element={<CustomReport />} />
            <Route path="/test/error" element={<TestError />} />
            <Route path="/notification" element={<NotificationList />} />

            <Route path="/configuration" element={<ConfigurationLayout />} />
            {CONFIGURATION_NAVIGATION.map(({ routePart: level1 }) => (
              <Route
                key={level1}
                path={`/configuration/${level1}`}
                element={<ConfigurationLayout level1={level1} />}
              >
                <Route
                  path=":level2"
                  element={<ConfigurationLayout level1={level1} />}
                >
                  <Route path=":id" element={Element} />
                </Route>
              </Route>
            ))}

            {objectList.map(([objectType, ObjectComponent]) => (
              <Route path={`/${objectType}`} key={objectType}>
                <Route
                  index
                  element={<ObjectComponent objectType={objectType} />}
                />
                <Route
                  path=":id"
                  element={<ObjectComponent objectType={objectType} />}
                />
              </Route>
            ))}
            {syncList.map((objectType) => (
              <Route
                key={objectType}
                path={`/${objectType}`}
                element={
                  <SyncPermission key={objectType} objectType={objectType} />
                }
              />
            ))}
            {(["development", "test"].includes(process.env.NODE_ENV) ||
              process.env.REACT_APP_ENVIRONMENT === "v9CI") && (
              <Route path="/dbreset" element={<DbReset />} />
            )}
            <Route path="*" element={<NotFound />} />
          </Route>
        </RouterRoutes>
      </Layout>
    </Box>
  );
}
