import { i18n } from "services/i18nService";
import { mainLog, LOG_LEVEL } from "services/logService";

import { openAlert } from "globalState/alertSlice";
import globalState from "globalState/globalState";

// level should be one of the values exported by LOG_LEVEL in logService.js
export function handleProgramError(error) {
  const errorMessage = error.message;
  const allowPlusFeatures =
    globalState.getState().userCompanySettings.settings.allowPlusFeatures;
  mainLog(error, LOG_LEVEL.ERROR);

  const message = `${i18n(
    allowPlusFeatures
      ? "error.InternalError.WithSupportNumber"
      : "error.InternalError",
    { errorMessage }
  )}`;

  globalState.dispatch(openAlert({ type: "error", message }));
}
