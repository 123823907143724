import { useState } from "react";
import { useSelector } from "react-redux";

import Delete from "@mui/icons-material/Delete";

import { ITEM_CALCULATED_FIELDS } from "appConfig";

import { Decimal, Money } from "classes/DecimalClasses";

import { QuickAddItem } from "components/QuickAddItem";
import { LineTableCell } from "components/formFields/LineTableCell";
import { LineAmount } from "components/formFields/line/LineAmount";
import { LineClass } from "components/formFields/line/LineClass";
import { LineCustomer } from "components/formFields/line/LineCustomer";
import { LineDescription } from "components/formFields/line/LineDescription";
import { LineInventoryItem } from "components/formFields/line/LineInventoryItem";
import { LineInventoryItemBin } from "components/formFields/line/LineInventoryItemBin";
import { LineJobWorkcenter } from "components/formFields/line/LineJobWorkcenter";
import { LineLinkedTransaction } from "components/formFields/line/LineLinkedTransaction";
import { LineLotWithExpiration } from "components/formFields/line/LineLotWithExpiration";
import { LineQuantity } from "components/formFields/line/LineQuantity";
import { LineReceived } from "components/formFields/line/LineReceived";
import { LineSerialsEnter } from "components/formFields/line/LineSerialsEnter";
import { LineTaxCode } from "components/formFields/line/LineTaxCode";
import { LineUnitprice } from "components/formFields/line/LineUnitprice";
import { LineUom } from "components/formFields/line/LineUom";
import { LineVendorPartNumber } from "components/formFields/line/LineVendorPartNumber";
import { LineVolumeAndVolumeUnit } from "components/formFields/line/LineVolumeAndVolumeUnit";
import { LineWeightAndWeightUnit } from "components/formFields/line/LineWeightAndWeightUnit";

import { i18n } from "services/i18nService";
import {
  getItemRecord,
  clearItemDataFromLine,
} from "services/sosInventoryService/domainLogic";
import { getEmptyRecord as getEmptyItemRecord } from "services/sosInventoryService/item/schema";
import { afterTouchLine } from "services/sosInventoryService/purchasingTransaction/afterTouchLine";
import {
  postItemAndCreatePurchasingLineItem,
  createPurchasingLineFromItem,
  updateLineWithItem,
} from "services/sosInventoryService/purchasingTransaction/domainLogic";
import { setPageDirty } from "services/utility/edit";
import { TYPES } from "services/utility/schema";

import { usePlans } from "hooks/usePlans";
import { usePrivileges } from "hooks/usePrivileges";

import { theme } from "SosTheme";
import {
  ITEM_QUICKLIST_FORM_TYPES,
  USER_PRIVILEGE,
  MAX_CHARS_LINE_ITEM_DESCRIPTION,
  OBJECT_TYPES,
} from "appConstants";
import {
  LINE_ITEM_METADATA,
  LINE_ITEM_FIELDS,
  EMPTY_LINE_ITEM,
} from "editConfig";

// created this wrapper to make drag and drop code easier to see
export function LineItem(props) {
  return <LineItemDetails {...props} />;
}

function LineItemDetails(props) {
  const {
    objectType,
    line,
    record,
    lineHandler,
    expandItemGroup,
    items,
    itemsCount,
    bins,
    showAppliedTo,
    showBinSerialAndLot,
    showReceived,
    addItem,
    taxCodes,
    classes,
    customers,
    jobWorkcenters,
    relatedRecords,
    lineItemsErrors: errors,
    setErrors,
  } = props;
  const { location, date, autoSerialLots } = record;

  const [addNewItem, setAddNewItem] = useState(false);
  const [newItemName, setNewItemName] = useState("");
  const [lineLoading, setLineLoading] = useState(false);

  const { hasPrivilegesOrIsAdmin } = usePrivileges();
  const viewCosts = hasPrivilegesOrIsAdmin(USER_PRIVILEGE.viewCosts);

  // SETTINGS
  const connectedToQBO = useSelector(
    (state) => state.userCompanySettings.settings.connectedToQBO
  );
  const showVolumeOnPurchases = useSelector(
    (state) => state.userCompanySettings.settings.showVolumeOnPurchases
  );
  const showWeightOnPurchases = useSelector(
    (state) => state.userCompanySettings.settings.showWeightOnPurchases
  );
  const defaultAssetAccount = useSelector(
    (state) => state.userCompanySettings.settings.defaultAssetAccount,
    (prev, curr) => curr?.id === prev?.id
  );
  const defaultCOGSAccount = useSelector(
    (state) => state.userCompanySettings.settings.defaultCOGSAccount,
    (prev, curr) => curr?.id === prev?.id
  );
  const defaultExpenseAccount = useSelector(
    (state) => state.userCompanySettings.settings.defaultExpenseAccount,
    (prev, curr) => curr?.id === prev?.id
  );
  const defaultIncomeAccount = useSelector(
    (state) => state.userCompanySettings.settings.defaultIncomeAccount,
    (prev, curr) => curr?.id === prev?.id
  );
  const defaultTaxCode = useSelector(
    (state) => state.userCompanySettings.settings.defaultTaxCode,
    (prev, curr) => curr?.id === prev?.id
  );
  const suggestPoQuantities = useSelector(
    (state) => state.userCompanySettings.settings.suggestPoQuantities
  );

  const { hasAtLeastPlusPlan } = usePlans();

  function isInError(field) {
    if (!errors[line.lineNumber]) {
      return false;
    }
    return Boolean(errors[line.lineNumber].find((f) => f === field));
  }

  function handleValueChange(fieldName, newValue) {
    let newLine;
    const lineItemFields = LINE_ITEM_FIELDS[objectType];
    if (lineItemFields[TYPES.money].includes(fieldName)) {
      newLine = { ...line, [fieldName]: new Money(newValue || 0) };
    } else {
      newLine = { ...line, [fieldName]: newValue };
    }
    setPageDirty();
    lineHandler({
      type: "update",
      updatedLine: afterTouchLine(newLine, fieldName),
    });
  }

  async function handleItemChange(newItem, userInput) {
    if (!newItem) {
      const updatedLine = clearItemDataFromLine(line);
      setPageDirty();
      lineHandler({ type: "update", updatedLine });
      return;
    }
    if (newItem?.id === "add") {
      return openAddItem(userInput);
    }
    setLineLoading(true);
    const objectItemFields = ITEM_CALCULATED_FIELDS[objectType];

    // conditonally add "suggestedQuantity" calculated field
    // if the account has suggestPoQuantities enabled
    const itemFields =
      objectType === OBJECT_TYPES.PURCHASE_ORDER.fullString &&
      suggestPoQuantities
        ? [...objectItemFields, "suggestedQuantity"]
        : objectItemFields;

    const item = await getItemRecord(
      newItem.id,
      location?.id,
      date,
      itemFields
    );
    const newLine = await updateLineWithItem(item, line, record.vendor);
    setPageDirty();
    lineHandler({
      type: "update",
      updatedLine: afterTouchLine(newLine, "item"),
    });
    setLineLoading(false);
  }

  function handleUomChange(_, uom) {
    setPageDirty();
    const updatedLine = afterTouchLine({ ...line, uom }, "uom");
    lineHandler({ type: "update", updatedLine });
  }

  function handleQuantityChange(_, newValue) {
    setPageDirty();
    const quantity = new Decimal(newValue || 0);
    const updatedLine = afterTouchLine({ ...line, quantity }, "quantity");
    lineHandler({ type: "update", updatedLine });
  }

  function handleReferenceChange(fieldName, newValue) {
    const newLine = { ...line, [fieldName]: newValue };
    const updatedLine = afterTouchLine(newLine, fieldName);
    setPageDirty();
    lineHandler({ type: "update", updatedLine });
  }

  async function onQuickAddItem(name) {
    const newItemSettings = {
      defaultCOGSAccount,
      defaultAssetAccount,
      defaultExpenseAccount,
      defaultIncomeAccount,
      defaultTaxCode,
    };
    const item = { ...getEmptyItemRecord(newItemSettings), name };
    const updatedLine = await postItemAndCreatePurchasingLineItem(
      item,
      EMPTY_LINE_ITEM[objectType],
      line.lineNumber,
      addItem,
      setErrors
    );
    lineHandler({ type: "update", updatedLine });
    setAddNewItem(false);
  }

  function handleArrayOfReferencesChange(fieldName, newValue) {
    const newLine = { ...line, [fieldName]: newValue };
    setPageDirty();
    lineHandler({
      type: "update",
      updatedLine: afterTouchLine(newLine, fieldName),
    });
  }

  function onAddItem(item) {
    setAddNewItem(false);
    const updatedLine = createPurchasingLineFromItem(
      item,
      EMPTY_LINE_ITEM[objectType],
      line.lineNumber,
      lineHandler
    );
    lineHandler({ type: "update", updatedLine });
    addItem();
  }

  function handleDelete() {
    setPageDirty();
    lineHandler({ type: "delete", deleteAt: line.lineNumber });
  }

  function openAddItem(userInput) {
    // handle "Add new item" in Item select input field; this does not change the
    // line item, so no need to proceed further into the handler
    setNewItemName(userInput);
    setAddNewItem(true);
  }

  if (!line) {
    return null;
  }

  return (
    <>
      <LineTableCell>
        <div
          onClick={handleDelete}
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 0.5rem",
            color: theme.palette.icons,
          }}
        >
          <Delete />
        </div>
      </LineTableCell>
      <LineTableCell>
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 0.5rem",
          }}
        >
          {line.lineNumber}
        </div>
      </LineTableCell>

      {showBinSerialAndLot ? (
        <LineInventoryItemBin
          line={line}
          objectType={objectType}
          itemFormType={ITEM_QUICKLIST_FORM_TYPES.PURCHASING}
          lineLoading={lineLoading}
          items={items}
          itemsCount={itemsCount}
          addItem={addItem}
          handleItemChange={handleItemChange}
          handleReferenceChange={handleValueChange}
          expandItemGroup={expandItemGroup}
          isInError={isInError}
          bins={bins}
          allowExpand
          location={relatedRecords.location}
        />
      ) : (
        <LineTableCell sx={{ minWidth: "13rem" }}>
          <LineInventoryItem
            lineLoading={lineLoading}
            line={line}
            itemFormType={ITEM_QUICKLIST_FORM_TYPES.PURCHASING}
            items={items}
            itemsCount={itemsCount}
            addItem={addItem}
            onValueChange={handleItemChange}
            allowExpand
            expandItemGroup={expandItemGroup}
            error={isInError("item")}
          />
        </LineTableCell>
      )}
      <LineTableCell sx={{ minWidth: "24rem" }}>
        <LineDescription
          value={line.description}
          onValueChange={handleValueChange}
          maxLength={
            connectedToQBO ? MAX_CHARS_LINE_ITEM_DESCRIPTION : undefined
          }
          error={isInError("description")}
        />
        {showBinSerialAndLot && !autoSerialLots && hasAtLeastPlusPlan && (
          <>
            {line.itemDetails?.serialTracking && (
              <LineSerialsEnter
                autoNumber={autoSerialLots}
                value={line.serials}
                onValueChange={handleArrayOfReferencesChange}
                quantity={line.quantity}
                error={isInError("serials")}
              />
            )}
            {line.itemDetails?.lotTracking && (
              <LineLotWithExpiration
                lot={line.lot}
                lotExpiration={line.lotExpiration}
                onValueChange={handleValueChange}
                itemLotTracked={line.itemDetails?.lotTracking}
                quantity={line.quantity}
                metadata={LINE_ITEM_METADATA[objectType]}
                lotError={isInError("lot")}
                lotExpirationError={isInError("lotExpiration")}
              />
            )}
          </>
        )}
      </LineTableCell>
      <LineVendorPartNumber
        value={line.vendorPartNumber}
        onValueChange={handleValueChange}
        error={isInError("vendorPartNumber")}
      />
      <LineQuantity
        objectType={objectType}
        value={line.quantity}
        suggestedQuantity={line.item.suggestedQuantity}
        onValueChange={handleQuantityChange}
        error={isInError("quantity")}
        disabled={lineLoading}
      />
      <LineUom
        value={line.uom}
        onValueChange={handleUomChange}
        itemUoms={line.itemDetails?.itemUoms}
        error={isInError("uom")}
      />
      {showWeightOnPurchases && (
        <LineWeightAndWeightUnit
          weight={line.weight}
          weightunit={line.weightunit}
        />
      )}
      {showVolumeOnPurchases && (
        <LineVolumeAndVolumeUnit
          volume={line.volume}
          volumeunit={line.volumeunit}
        />
      )}
      {viewCosts && (
        <LineUnitprice
          value={line.unitprice}
          objectType={objectType}
          onValueChange={handleValueChange}
          error={isInError("unitprice")}
        />
      )}
      {viewCosts && (
        <LineAmount
          value={line.amount}
          objectType={objectType}
          onValueChange={handleValueChange}
          error={isInError("amount")}
        />
      )}
      {showAppliedTo && (
        <LineLinkedTransaction
          objectType={objectType}
          transactionLabel={i18n("purchaseOrder.labelAbbreviated")}
          value={line.linkedTransaction}
          onValueChange={handleValueChange}
          error={isInError("linkedTransaction")}
        />
      )}
      {showReceived && (
        <LineReceived
          objectType={objectType}
          value={line.received}
          onValueChange={handleValueChange}
          error={isInError("received")}
        />
      )}
      <LineClass
        value={line.class}
        classes={classes}
        onValueChange={handleReferenceChange}
      />
      <LineTaxCode
        value={line.taxCode}
        taxCodes={taxCodes}
        onValueChange={handleReferenceChange}
        error={isInError("taxCode")}
      />
      <LineCustomer
        value={line.customer}
        customers={customers}
        onValueChange={handleReferenceChange}
        error={isInError("customer")}
      />
      <LineJobWorkcenter
        job={line.job}
        workcenter={line.workcenter}
        value={line.jobWorkcenter}
        jobWorkcenters={jobWorkcenters}
        onValueChange={handleReferenceChange}
      />
      {addNewItem && (
        <QuickAddItem
          open={addNewItem}
          initialNameValue={newItemName}
          onClose={() => setAddNewItem(false)}
          onAdd={onAddItem}
          onQuickAdd={onQuickAddItem}
        />
      )}
    </>
  );
}
