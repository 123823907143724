// A custom report is based on a standard report (reportType), and includes
// settings for
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import { getRecord } from "services/sosInventoryService/sosApi";

import { ReportPage } from "views/Reports/ReportPage";
import { REPORTS } from "views/Reports/reportConfig";

import { Forbidden } from "Forbidden";

export function CustomReport() {
  const { id: idString } = useParams();
  const id = parseInt(idString);

  const [customSettings, setCustomSettings] = useState();

  useEffect(() => {
    async function getCustom(id) {
      setCustomSettings(await getRecord("customReport", id));
    }
    getCustom(id);
  }, [id]);

  const restrictedSalesRep = useSelector(
    (state) => state.userCompanySettings.company.restrictedSalesRep
  );

  if (restrictedSalesRep) {
    return <Forbidden />;
  }

  if (!customSettings) {
    return null;
  }

  const reportConfig = {
    id,
    name: customSettings.name,
    reportType: customSettings.type,
    ...REPORTS[customSettings.type],
    settingsValues: customSettings.settingsValues,
    schedule: {
      ...customSettings.schedule,
      saved: Boolean(customSettings.schedule),
    },
    columnsValue: customSettings.columns,
  };

  return <ReportPage reportConfig={reportConfig} />;
}
