import { createContext, useEffect, useState } from "react";

import { Grid2, DialogTitle, DialogContent, Typography } from "@mui/material";

import { List } from "components/ListPage/List/Row/History/Rental/List";
import { Widgets } from "components/ListPage/List/Row/History/Widgets";
import { CloseX } from "components/utility/CloseX";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { Loading } from "components/utility/Loading";
import { ModalTitle } from "components/utility/ModalTitle";

import { i18n } from "services/i18nService";
import { getItemRecord } from "services/sosInventoryService/domainLogic";
import { TYPE_FILTER_OPTIONS } from "services/sosInventoryService/rentalHistory/schema";
import { getRentalHistory } from "services/sosInventoryService/sosApi";
import { calendarFormat } from "services/utility/dates";

import { useErrors } from "hooks/useErrors";

import { ITEM_HISTORY_DEFAULT_FILTERS } from "appConstants";

export const ListPageState = createContext();

export function ItemHistory(props) {
  const { setOpen, objectType, id, identifierText } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [quantity, setQuantity] = useState(null);
  const [retrievalParams, setRetrievalParams] = useState(
    ITEM_HISTORY_DEFAULT_FILTERS
  );

  const [history, setHistory] = useState(null);
  const [totalHistoryCount, setTotalHistoryCount] = useState(null);

  const { errors, setErrors } = useErrors();

  // fetches list data
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const { start, maxResults, direction, query, sort } = retrievalParams;
      const { fromLocation, toLocation, type, toDate, fromDate } =
        retrievalParams;
      const response = await getRentalHistory(objectType, id, {
        direction,
        maxResults,
        query,
        sort,
        start,
        fromLocation: fromLocation.id ? fromLocation.name : "",
        toLocation: toLocation.id ? toLocation.name : "",
        type: type.id ? type.name : "",
        to: toDate ? calendarFormat(toDate) : "",
        from: fromDate ? calendarFormat(fromDate) : "",
      });
      // response can be undefined, if the get was aborted by further
      // typing in the search field
      if (response) {
        setIsLoading(false);
        setHistory(response.data);
        setTotalHistoryCount(response.totalCount);
      }
    })();
  }, [retrievalParams, id, objectType]);

  // fetches item quantity value
  useEffect(() => {
    (async () => {
      const { onhand } = await getItemRecord(id, undefined, undefined, [
        "onhand",
      ]);
      setQuantity(onhand);
    })();
  }, [retrievalParams, id, objectType]);

  return (
    <>
      <DialogTitle
        sx={{
          backgroundColor: "dialogTitleBackground",
          borderBottom: "1px solid",
          borderBottomColor: "lineSeparatorMinor",
          padding: "0.5rem 1rem",
        }}
      >
        <Grid2
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid2 size={8}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CloseX onClick={() => setOpen(false)} />
              <ModalTitle
                label={i18n("global.History")}
                text={identifierText}
              />
            </div>
          </Grid2>
        </Grid2>
      </DialogTitle>
      {isLoading && <Loading />}
      <DialogContent>
        <FormErrors errors={errors} setErrors={setErrors} />
        {quantity && (
          <Typography sx={{ marginTop: "1rem" }}>
            <>
              <span style={{ fontWeight: "bold" }}>
                {i18n("colon.CurrentQuantity")}
              </span>
              <span> {quantity.toString()}</span>
            </>
          </Typography>
        )}
        {history ? (
          <>
            <Widgets
              totalHistoryCount={totalHistoryCount}
              retrievalParams={retrievalParams}
              setRetrievalParams={setRetrievalParams}
              typeOptions={TYPE_FILTER_OPTIONS}
            />
            <List
              history={history}
              retrievalParams={retrievalParams}
              setRetrievalParams={setRetrievalParams}
            />
          </>
        ) : (
          <FixedLoadingIndicator text={`${i18n("global.Loading")}...`} />
        )}
      </DialogContent>
    </>
  );
}
