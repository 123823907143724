import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Grid2,
  DialogTitle,
  DialogContent,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";

import { Money } from "classes/DecimalClasses";
import { Decimal } from "classes/DecimalClasses";

import { TableCell } from "components/TableCell";
import { CloseX } from "components/utility/CloseX";
import { LinkText } from "components/utility/LinkText";
import { ModalTitle } from "components/utility/ModalTitle";

import { i18n } from "services/i18nService";
import { getHistory } from "services/sosInventoryService/sosApi";
import { formatDate } from "services/utility/dates";
import { formatMoney } from "services/utility/formatting";

import { openModal } from "globalState/modalSlice";

import { theme } from "SosTheme";
import { getObjectFromTypeString, NO_REF_NUMBER_STRING } from "appConstants";

const styles = { verticalAlign: "bottom" };

const MONEY_ZERO = new Money(0);

const COLUMNS = { serial: { value: true }, lot: { quantity: true } };

// if revenue <= 0, use cost, otherwise use revenue
function calculateValue(cost, revenue) {
  if (revenue?.gt(MONEY_ZERO)) {
    return revenue;
  }
  if (!cost?.eq(MONEY_ZERO)) {
    return cost;
  }
  return "";
}

function quantity(quantity, outputQuantity) {
  if (!quantity.eq(Decimal.ZERO)) {
    return quantity.toString();
  } else {
    return outputQuantity.toString();
  }
}

export function SerialLotHistory(props) {
  const { onClose, objectType, id, identifierText } = props;

  const dispatch = useDispatch();
  const [history, setHistory] = useState(undefined);

  useEffect(() => {
    async function retrieveHistory() {
      const { data } = await getHistory(objectType, id);
      setHistory(data);
    }
    retrieveHistory();
  }, [objectType, id]);

  function getSerialHistoryString(fullString, direction) {
    const lookupString = direction ? `${fullString}.${direction}` : fullString;
    return i18n(`serialLotEvent.${lookupString}`);
  }

  if (!history) {
    return null;
  }

  function showColumn(columnName) {
    return COLUMNS[objectType][columnName];
  }
  const net =
    objectType === "serial"
      ? history.reduce(
          (acc, current) =>
            acc.plus(
              calculateValue(current.cost, current.revenue) || MONEY_ZERO
            ),
          MONEY_ZERO
        )
      : undefined;

  return (
    <>
      <DialogTitle
        sx={{
          backgroundColor: "tooltipBackground",
          borderBottom: "1px solid " + theme.palette.lineSeparatorMinor,
          padding: "0.5rem 1rem",
        }}
      >
        <Grid2
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid2 size={8}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CloseX onClick={onClose} />
              <ModalTitle
                label={i18n("global.History")}
                text={identifierText}
              />
            </div>
          </Grid2>
        </Grid2>
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={styles}>{i18n("history.txnDate")}</TableCell>
                {showColumn("quantity") && (
                  <TableCell sx={styles} align="right">
                    {i18n("history.quantity")}
                  </TableCell>
                )}
                <TableCell sx={styles}>{i18n("history.type")}</TableCell>
                <TableCell sx={styles}>{i18n("history.refNumber")}</TableCell>
                <TableCell sx={styles}>
                  {i18n("history.fromLocation")}
                </TableCell>
                <TableCell sx={styles}>{i18n("history.toLocation")}</TableCell>
                <TableCell sx={styles}>{i18n("history.vendorName")}</TableCell>
                <TableCell sx={styles}>
                  {i18n("history.customerName")}
                </TableCell>
                {showColumn("value") && (
                  <TableCell sx={styles} align="right">
                    {i18n("history.value")}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {history.map((h, index) => {
                const { fullString } = getObjectFromTypeString(h.type);
                return (
                  <TableRow key={index}>
                    <TableCell>{formatDate(h.txnDate)}</TableCell>
                    {showColumn("quantity") && (
                      <TableCell align="right">
                        {quantity(h.quantity, h.outputQuantity)}
                      </TableCell>
                    )}
                    <TableCell>
                      {getSerialHistoryString(fullString, h.direction)}
                    </TableCell>
                    <TableCell>
                      <LinkText
                        onClick={() =>
                          dispatch(
                            openModal({
                              objectType: fullString,
                              modalProps: { id: h.id },
                            })
                          )
                        }
                      >
                        {h.refNumber || NO_REF_NUMBER_STRING}
                      </LinkText>
                    </TableCell>
                    <TableCell>{h.fromLocation}</TableCell>
                    <TableCell>{h.toLocation}</TableCell>
                    <TableCell>{h.vendorName}</TableCell>
                    <TableCell>{h.customerName}</TableCell>
                    {showColumn("value") && (
                      <TableCell align="right">
                        {formatMoney(calculateValue(h.cost, h.revenue))}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}

              {objectType === "serial" && (
                <TableRow>
                  <TableCell colSpan={6} />
                  <TableCell>
                    <b>{i18n("history.NET")}</b>
                  </TableCell>
                  <TableCell align="right">
                    <b>{formatMoney(net)}</b>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </>
  );
}
