import {
  SERVER,
  ITEM_MAX_RESULTS,
  TEMPLATE_NAMES,
  IN_TRANSACTION,
  IN_REPORT,
} from "appConfig";

import { i18n } from "services/i18nService";
import { log } from "services/logService";
import { afterGet as afterGetAddress } from "services/sosInventoryService/address/afterGet";
import { beforeSave as beforeSaveAddress } from "services/sosInventoryService/address/beforeSave";
import { afterGet as afterGetAdjustment } from "services/sosInventoryService/adjustment/afterGet";
import { beforeSave as beforeSaveAdjustment } from "services/sosInventoryService/adjustment/beforeSave";
import { beforeSave as beforeSaveAopRule } from "services/sosInventoryService/aopRule/beforeSave";
import { afterGet as afterGetAuditHistory } from "services/sosInventoryService/auditHistory/afterGet";
import { afterGet as afterGetBill } from "services/sosInventoryService/bill/afterGet";
import { afterGet as afterGetBillPayment } from "services/sosInventoryService/billPayment/afterGet";
import { afterGet as afterGetBins } from "services/sosInventoryService/bin/afterGet";
import { afterGet as afterGetBom } from "services/sosInventoryService/bom/afterGet";
import { beforeSave as beforeSaveBom } from "services/sosInventoryService/bom/beforeSave";
import { afterGet as afterGetBox } from "services/sosInventoryService/box/afterGet";
import { beforeSave as beforeSaveBox } from "services/sosInventoryService/box/beforeSave";
import { afterGet as afterGetBuild } from "services/sosInventoryService/build/afterGet";
import { beforeSave as beforeSaveBuild } from "services/sosInventoryService/build/beforeSave";
import { afterGet as afterGetComment } from "services/sosInventoryService/comment/afterGet";
import { afterGet as afterGetCompanyAccountInfo } from "services/sosInventoryService/company/accountInfo/afterGet";
import { afterGet as afterGetCompanyUserInfo } from "services/sosInventoryService/companyUserInfo/afterGet";
import { afterGet as afterGetCostBasis } from "services/sosInventoryService/costBasis/afterGet";
import { afterGet as afterGetCreditMemo } from "services/sosInventoryService/creditMemo/afterGet";
import { afterGet as afterGetCustomReport } from "services/sosInventoryService/customReport/afterGet";
import { afterGet as afterGetCustomer } from "services/sosInventoryService/customer/afterGet";
import { beforeSave as beforeSaveCustomer } from "services/sosInventoryService/customer/beforeSave";
import { afterGet as afterGetCustomerHistory } from "services/sosInventoryService/customerHistory/afterGet";
import { afterGet as afterGetVendorMessage } from "services/sosInventoryService/customerMessage/afterGet";
import { afterGet as afterGetDeletedItem } from "services/sosInventoryService/deletedItem/afterGet";
import { afterGet as afterGetDocument } from "services/sosInventoryService/document/afterGet";
import { beforeSave as beforeSaveDocument } from "services/sosInventoryService/document/beforeSave";
import { afterGet as afterGetEstimate } from "services/sosInventoryService/estimate/afterGet";
import { beforeSave as beforeSaveEstimate } from "services/sosInventoryService/estimate/beforeSave";
import { afterGet as afterGetFormTemplate } from "services/sosInventoryService/formTemplate/afterGet";
import { beforeSave as beforeSaveFormTemplate } from "services/sosInventoryService/formTemplate/beforeSave";
import { afterGet as afterGetFsPayment } from "services/sosInventoryService/fsPayment/afterGet";
import { afterGet as afterGetInvoice } from "services/sosInventoryService/invoice/afterGet";
import { beforeSave as beforeSaveInvoice } from "services/sosInventoryService/invoice/beforeSave";
import { afterGet as afterGetItem } from "services/sosInventoryService/item/afterGet";
import { beforeSave as beforeSaveItem } from "services/sosInventoryService/item/beforeSave";
import { afterGet as afterGetItemHistory } from "services/sosInventoryService/itemHistory/afterGet";
import { afterGet as afterGetItemInventory } from "services/sosInventoryService/itemInventory/afterGet";
import { afterGet as afterGetItemReceipt } from "services/sosInventoryService/itemReceipt/afterGet";
import { beforeSave as beforeSaveItemReceipt } from "services/sosInventoryService/itemReceipt/beforeSave";
import { afterGet as afterGetjob } from "services/sosInventoryService/job/afterGet";
import { beforeSave as beforeSaveJob } from "services/sosInventoryService/job/beforeSave";
import { afterGet as afterGetJobDashboard } from "services/sosInventoryService/jobDashboard/afterGet";
import { afterGet as afterGetJobProfitLoss } from "services/sosInventoryService/jobProfitLoss/afterGet";
import { afterGet as afterGetJournalEntry } from "services/sosInventoryService/journalEntry/afterGet";
import { afterGet as afterGetListSettings } from "services/sosInventoryService/listSettings/afterGet";
import { afterGet as afterGetLot } from "services/sosInventoryService/lot/afterGet";
import { beforeSave as beforeSaveLot } from "services/sosInventoryService/lot/beforeSave";
import { afterGet as afterGetLotHistory } from "services/sosInventoryService/lotHistory/afterGet";
import { afterGet as afterGetNotification } from "services/sosInventoryService/notification/afterGet";
import { afterGet as afterGetPayment } from "services/sosInventoryService/payment/afterGet";
import { beforeSave as beforeSavePayment } from "services/sosInventoryService/payment/beforeSave";
import { afterGet as afterGetPaymentHistory } from "services/sosInventoryService/paymentHistory/afterGet";
import { afterGet as afterGetPickTicket } from "services/sosInventoryService/pickTicket/afterGet";
import { beforeSave as beforeSavePickTicket } from "services/sosInventoryService/pickTicket/beforeSave";
import { afterGet as afterGetPriceTier } from "services/sosInventoryService/priceTier/afterGet";
import { beforeSave as beforeSavePriceTier } from "services/sosInventoryService/priceTier/beforeSave";
import { afterGet as afterGetProcess } from "services/sosInventoryService/process/afterGet";
import { beforeSave as beforeSaveProcess } from "services/sosInventoryService/process/beforeSave";
import { afterGet as afterGetProcessTemplate } from "services/sosInventoryService/processTemplate/afterGet";
import { beforeSave as beforeSaveProcessTemplate } from "services/sosInventoryService/processTemplate/beforeSave";
import { afterGet as afterGetPurchase } from "services/sosInventoryService/purchase/afterGet";
import { afterGet as afterGetPurchaseOrder } from "services/sosInventoryService/purchaseOrder/afterGet";
import { beforeSave as beforeSavePurchaseOrder } from "services/sosInventoryService/purchaseOrder/beforeSave";
import { afterGet as afterGetRecentHistory } from "services/sosInventoryService/recentHistory/afterGet";
import { afterGet as afterGetRental } from "services/sosInventoryService/rental/afterGet";
import { beforeSave as beforeSaveRental } from "services/sosInventoryService/rental/beforeSave";
import { afterGet as afterGetRentalHistory } from "services/sosInventoryService/rentalHistory/afterGet";
import { afterGet as afterGetRentalReturn } from "services/sosInventoryService/rentalReturn/afterGet";
import { beforeSave as beforeSaveRentalReturn } from "services/sosInventoryService/rentalReturn/beforeSave";
import { afterGet as afterGetReorder } from "services/sosInventoryService/reorder/afterGet";
import { beforeSave as beforeSaveReorder } from "services/sosInventoryService/reorder/beforeSave";
import { afterGet as afterGetReport } from "services/sosInventoryService/report/afterGet";
import { afterGet as afterGetReturn } from "services/sosInventoryService/return/afterGet";
import { beforeSave as beforeSaveReturn } from "services/sosInventoryService/return/beforeSave";
import { afterGet as afterGetReturnToVendor } from "services/sosInventoryService/returnToVendor/afterGet";
import { beforeSave as beforeSaveReturnToVendor } from "services/sosInventoryService/returnToVendor/beforeSave";
import { afterGet as afterGetRma } from "services/sosInventoryService/rma/afterGet";
import { beforeSave as beforeSaveRma } from "services/sosInventoryService/rma/beforeSave";
import { afterGet as afterGetSalesOrder } from "services/sosInventoryService/salesOrder/afterGet";
import { beforeSave as beforeSaveSalesOrder } from "services/sosInventoryService/salesOrder/beforeSave";
import { afterGet as afterGetSalesOrderProfitLoss } from "services/sosInventoryService/salesOrderProfitLoss/afterGet";
import { afterGet as afterGetSalesReceipt } from "services/sosInventoryService/salesReceipt/afterGet";
import { beforeSave as beforeSaveSalesReceipt } from "services/sosInventoryService/salesReceipt/beforeSave";
import { afterGet as afterGetSerial } from "services/sosInventoryService/serial/afterGet";
import { beforeSave as beforeSaveSerial } from "services/sosInventoryService/serial/beforeSave";
import { afterGet as afterGetSerialHistory } from "services/sosInventoryService/serialHistory/afterGet";
import { afterGet as afterGetSettings } from "services/sosInventoryService/settings/afterGet";
import { afterGet as afterGetSettingsAccounting } from "services/sosInventoryService/settings/company/accounting/afterGet";
import { beforeSave as beforeSaveSettingsAccounting } from "services/sosInventoryService/settings/company/accounting/beforeSave";
import { afterGet as afterGetSettingsCompanyGeneral } from "services/sosInventoryService/settings/company/general/afterGet";
import { beforeSave as beforeSaveSettingsCompanyGeneral } from "services/sosInventoryService/settings/company/general/beforeSave";
import { afterGet as afterGetSettingsnameAndAddress } from "services/sosInventoryService/settings/company/nameAndAddress/afterGet";
import { beforeSave as beforeSaveSettingsNameAndAddress } from "services/sosInventoryService/settings/company/nameAndAddress/beforeSave";
import { afterGet as afterGetSettingsConnectionsQuickBooks } from "services/sosInventoryService/settings/connections/quickBooks/afterGet";
import { beforeSave as beforeSaveSettingsConnectionsQuickBooks } from "services/sosInventoryService/settings/connections/quickBooks/beforeSave";
import { afterGet as afterGetSettingsFulfillmentPickTickets } from "services/sosInventoryService/settings/fulfillment/pickTickets/afterGet";
import { beforeSave as beforeSaveSettingsFulfillmentPickTickets } from "services/sosInventoryService/settings/fulfillment/pickTickets/beforeSave";
import { afterGet as afterGetSettingsFulfillmentShipments } from "services/sosInventoryService/settings/fulfillment/shipments/afterGet";
import { beforeSave as beforeSaveSettingsFulfillmentShipments } from "services/sosInventoryService/settings/fulfillment/shipments/beforeSave";
import { afterGet as afterGetSettingsInventoryBarcode } from "services/sosInventoryService/settings/inventory/barcode/afterGet";
import { beforeSave as beforeSaveSettingsInventoryBarcode } from "services/sosInventoryService/settings/inventory/barcode/beforeSave";
import { afterGet as afterGetSettingsInventoryGeneral } from "services/sosInventoryService/settings/inventory/general/afterGet";
import { beforeSave as beforeSaveSettingsInventoryGeneral } from "services/sosInventoryService/settings/inventory/general/beforeSave";
import { afterGet as afterGetSettingsInventoryTransactions } from "services/sosInventoryService/settings/inventory/transactions/afterGet";
import { beforeSave as beforeSaveSettingsInventoryTransactions } from "services/sosInventoryService/settings/inventory/transactions/beforeSave";
import { afterGet as afterGetSettingsPaymentsGeneral } from "services/sosInventoryService/settings/payments/general/afterGet";
import { beforeSave as beforeSaveSettingsPaymentsGeneral } from "services/sosInventoryService/settings/payments/general/beforeSave";
import { afterGet as afterGetSettingsProductionBuilds } from "services/sosInventoryService/settings/production/builds/afterGet";
import { beforeSave as beforeSaveSettingsProductionBuilds } from "services/sosInventoryService/settings/production/builds/beforeSave";
import { afterGet as afterGetSettingsProductionJobs } from "services/sosInventoryService/settings/production/jobs/afterGet";
import { beforeSave as beforeSaveSettingsProductionJobs } from "services/sosInventoryService/settings/production/jobs/beforeSave";
import { afterGet as afterGetSettingsProductionProcesses } from "services/sosInventoryService/settings/production/processes/afterGet";
import { beforeSave as beforeSaveSettingsProductionProcesses } from "services/sosInventoryService/settings/production/processes/beforeSave";
import { afterGet as afterGetSettingsProductionWorkOrders } from "services/sosInventoryService/settings/production/workOrders/afterGet";
import { beforeSave as beforeSaveSettingsProductionWorkOrders } from "services/sosInventoryService/settings/production/workOrders/beforeSave";
import { afterGet as afterGetSettingsPurchasingItemReceipts } from "services/sosInventoryService/settings/purchasing/itemReceipts/afterGet";
import { beforeSave as beforeSaveSettingsPurchasingItemReceipts } from "services/sosInventoryService/settings/purchasing/itemReceipts/beforeSave";
import { afterGet as afterGetSettingsPurchasingPurchaseOrders } from "services/sosInventoryService/settings/purchasing/purchaseOrders/afterGet";
import { beforeSave as beforeSaveSettingsPurchasingPurchaseOrders } from "services/sosInventoryService/settings/purchasing/purchaseOrders/beforeSave";
import { afterGet as afterGetSettingsPurchasingReturnsToVendors } from "services/sosInventoryService/settings/purchasing/returnsToVendors/afterGet";
import { beforeSave as beforeSaveSettingsPurchasingReturnsToVendors } from "services/sosInventoryService/settings/purchasing/returnsToVendors/beforeSave";
import { afterGet as afterGetSettingsRentalReturns } from "services/sosInventoryService/settings/rentals/rentalReturns/afterGet";
import { beforeSave as beforeSaveSettingsRentalReturns } from "services/sosInventoryService/settings/rentals/rentalReturns/beforeSave";
import { afterGet as afterGetSettingsRentalsRentals } from "services/sosInventoryService/settings/rentals/rentals/afterGet";
import { beforeSave as beforeSaveSettingsRentalsRentals } from "services/sosInventoryService/settings/rentals/rentals/beforeSave";
import { afterGet as afterGetSettingsSalesEstimates } from "services/sosInventoryService/settings/sales/estimates/afterGet";
import { beforeSave as beforeSaveSettingsSalesEstimates } from "services/sosInventoryService/settings/sales/estimates/beforeSave";
import { afterGet as afterGetSettingsSalesInvoices } from "services/sosInventoryService/settings/sales/invoices/afterGet";
import { beforeSave as beforeSaveSettingsSalesInvoices } from "services/sosInventoryService/settings/sales/invoices/beforeSave";
import { afterGet as afterGetSettingsSalesReturns } from "services/sosInventoryService/settings/sales/returns/afterGet";
import { beforeSave as beforeSaveSettingsSalesReturns } from "services/sosInventoryService/settings/sales/returns/beforeSave";
import { afterGet as afterGetSettingsSalesRmas } from "services/sosInventoryService/settings/sales/rmas/afterGet";
import { beforeSave as beforeSaveSettingsSalesRmas } from "services/sosInventoryService/settings/sales/rmas/beforeSave";
import { afterGet as afterGetSettingsSalesSalesOrders } from "services/sosInventoryService/settings/sales/salesOrders/afterGet";
import { beforeSave as beforeSaveSettingsSalesSalesOrders } from "services/sosInventoryService/settings/sales/salesOrders/beforeSave";
import { afterGet as afterGetSettingsSalesSalesReceipts } from "services/sosInventoryService/settings/sales/salesReceipts/afterGet";
import { beforeSave as beforeSaveSettingsSalesSalesReceipts } from "services/sosInventoryService/settings/sales/salesReceipts/beforeSave";
import { afterGet as afterGetSettingsUserGeneral } from "services/sosInventoryService/settings/user/general/afterGet";
import { beforeSave as beforeSaveSettingsUserGeneral } from "services/sosInventoryService/settings/user/general/beforeSave";
import { afterGet as afterGetShipment } from "services/sosInventoryService/shipment/afterGet";
import { beforeSave as beforeSaveShipment } from "services/sosInventoryService/shipment/beforeSave";
import { afterGet as afterGetSupportPrivileges } from "services/sosInventoryService/supportPrivileges/afterGet";
import { afterGet as afterGetSyncError } from "services/sosInventoryService/syncError/afterGet";
import { afterGet as afterGetSyncItem } from "services/sosInventoryService/syncItem/afterGet";
import { afterGet as afterGetTask } from "services/sosInventoryService/task/afterGet";
import { afterGet as afterGetTaxCode } from "services/sosInventoryService/taxCode/afterGet";
import { beforeSave as beforeSaveTemplateLibrary } from "services/sosInventoryService/templateLibrary/beforeSave";
import { beforeSave as beforeSaveTerms } from "services/sosInventoryService/term/beforeSave";
import { afterGet as afterGetTransfer } from "services/sosInventoryService/transfer/afterGet";
import { beforeSave as beforeSaveTransfer } from "services/sosInventoryService/transfer/beforeSave";
import { afterGet as afterGetUomSet } from "services/sosInventoryService/uomSet/afterGet";
import { beforeSave as beforeSaveUomSet } from "services/sosInventoryService/uomSet/beforeSave";
import { afterGet as afterGetUpsShipment } from "services/sosInventoryService/upsShipment/afterGet";
import { afterGet as afterGetUser } from "services/sosInventoryService/user/afterGet";
import { afterGet as afterGetVariant } from "services/sosInventoryService/variant/afterGet";
import { afterGet as afterGetVendor } from "services/sosInventoryService/vendor/afterGet";
import { beforeSave as beforeSaveVendor } from "services/sosInventoryService/vendor/beforeSave";
import { afterGet as afterGetVendorHistory } from "services/sosInventoryService/vendorHistory/afterGet";
import { afterGet as afterGetVendorItem } from "services/sosInventoryService/vendorItem/afterGet";
import { afterGet as afterGetCustomerMessage } from "services/sosInventoryService/vendorMessage/afterGet";
import { afterGet as afterGetWorkCenter } from "services/sosInventoryService/workCenter/afterGet";
import { afterGet as afterGetWorkOrder } from "services/sosInventoryService/workOrder/afterGet";
import { beforeSave as beforeSaveWorkOrder } from "services/sosInventoryService/workOrder/beforeSave";
import { logout } from "services/utility/authentication";
import { getBearerToken } from "services/utility/authentication";
import { dateToSosISODateTime, dateToSosISODate } from "services/utility/dates";
import { handleProgramError } from "services/utility/errors";
import { objectToQueryParams } from "services/utility/http";
import { splitArrByAmt } from "services/utility/misc";

import {
  getObjectFromFullString,
  NO_COLUMNS,
  LIST_MAX_MAX_RESULTS,
  ITEM_QUICKLIST_CASES,
} from "appConstants";
import { format } from "date-fns";

function nullFunction(record) {
  return record;
}

const CALLBACKS = {
  afterGet: {
    "company/accountInfo": afterGetCompanyAccountInfo,
    account: nullFunction,
    address: afterGetAddress,
    adjustment: afterGetAdjustment,
    alert: nullFunction,
    aoprule: nullFunction,
    auditHistory: afterGetAuditHistory,
    bins: afterGetBins,
    bill: afterGetBill,
    billpayment: afterGetBillPayment,
    bom: afterGetBom,
    box: afterGetBox,
    build: afterGetBuild,
    channel: nullFunction,
    class: nullFunction,
    comment: afterGetComment,
    companyUserInfo: afterGetCompanyUserInfo,
    country: nullFunction,
    creditmemo: afterGetCreditMemo,
    currency: nullFunction,
    customer: afterGetCustomer,
    customerHistory: afterGetCustomerHistory,
    customermessage: afterGetCustomerMessage,
    customertype: nullFunction,
    customfield: nullFunction,
    customReport: afterGetCustomReport,
    deleteditem: afterGetDeletedItem,
    department: nullFunction,
    document: afterGetDocument,
    employee: nullFunction,
    estimate: afterGetEstimate,
    formtemplate: afterGetFormTemplate,
    fspayment: afterGetFsPayment,
    ftpconnection: nullFunction,
    invoice: afterGetInvoice,
    item: afterGetItem,
    itemHistory: afterGetItemHistory,
    itemInventory: afterGetItemInventory,
    itemreceipt: afterGetItemReceipt,
    job: afterGetjob,
    jobProfitLoss: afterGetJobProfitLoss,
    jobDashboard: afterGetJobDashboard,
    journalentry: afterGetJournalEntry,
    listSettings: afterGetListSettings,
    lot: afterGetLot,
    lotHistory: afterGetLotHistory,
    location: nullFunction,
    notification: afterGetNotification,
    orderstage: nullFunction,
    parent: nullFunction,
    payment: afterGetPayment,
    paymentHistory: afterGetPaymentHistory,
    paymentmethod: nullFunction,
    pickticket: afterGetPickTicket,
    pricetier: afterGetPriceTier,
    priority: nullFunction,
    process: afterGetProcess,
    processtemplate: afterGetProcessTemplate,
    purchase: afterGetPurchase,
    purchaseorder: afterGetPurchaseOrder,
    recentHistory: afterGetRecentHistory,
    rental: afterGetRental,
    rentalreturn: afterGetRentalReturn,
    rentalHistory: afterGetRentalHistory,
    reorder: afterGetReorder,
    report: afterGetReport,
    return: afterGetReturn,
    rma: afterGetRma,
    returntovendor: afterGetReturnToVendor,
    salesorder: afterGetSalesOrder,
    salesorderProfitLoss: afterGetSalesOrderProfitLoss,
    salesreceipt: afterGetSalesReceipt,
    salesrep: nullFunction,
    // the manipulation needed for settings in scheduled standard reports
    // will eventually go away, so using the identical customreport manipu-
    // lation in the meantime
    schedule: afterGetCustomReport,
    syncerror: afterGetSyncError,
    serial: afterGetSerial,
    serialHistory: afterGetSerialHistory,
    settings: afterGetSettings,
    shipment: afterGetShipment,
    shipmethod: nullFunction,
    status: nullFunction,
    supportprivileges: afterGetSupportPrivileges,
    syncitem: afterGetSyncItem,
    task: afterGetTask,
    taxcode: afterGetTaxCode,
    tag: nullFunction,
    terms: nullFunction,
    templatelibrary: nullFunction,
    transfer: afterGetTransfer,
    uom: nullFunction,
    uomSet: afterGetUomSet,
    upsshipment: afterGetUpsShipment,
    user: afterGetUser,
    variant: afterGetVariant,
    vendor: afterGetVendor,
    vendorHistory: afterGetVendorHistory,
    vendoritem: afterGetVendorItem,
    vendormessage: afterGetVendorMessage,
    warranty: nullFunction,
    workcenter: afterGetWorkCenter,
    worker: nullFunction,
    workorder: afterGetWorkOrder,
  },
  beforeSave: {
    address: beforeSaveAddress,
    adjustment: beforeSaveAdjustment,
    aoprule: beforeSaveAopRule,
    bom: beforeSaveBom,
    box: beforeSaveBox,
    build: beforeSaveBuild,
    customer: beforeSaveCustomer,
    document: beforeSaveDocument,
    estimate: beforeSaveEstimate,
    formtemplate: beforeSaveFormTemplate,
    invoice: beforeSaveInvoice,
    item: beforeSaveItem,
    itemreceipt: beforeSaveItemReceipt,
    job: beforeSaveJob,
    lot: beforeSaveLot,
    payment: beforeSavePayment,
    pickticket: beforeSavePickTicket,
    process: beforeSaveProcess,
    pricetier: beforeSavePriceTier,
    processtemplate: beforeSaveProcessTemplate,
    purchaseorder: beforeSavePurchaseOrder,
    rental: beforeSaveRental,
    rentalreturn: beforeSaveRentalReturn,
    reorder: beforeSaveReorder,
    return: beforeSaveReturn,
    returntovendor: beforeSaveReturnToVendor,
    rma: beforeSaveRma,
    salesorder: beforeSaveSalesOrder,
    salesreceipt: beforeSaveSalesReceipt,
    serial: beforeSaveSerial,
    shipment: beforeSaveShipment,
    terms: beforeSaveTerms,
    templatelibrary: beforeSaveTemplateLibrary,
    transfer: beforeSaveTransfer,
    uomSet: beforeSaveUomSet,
    variant: afterGetVariant,
    vendor: beforeSaveVendor,
    workorder: beforeSaveWorkOrder,
  },
};

const SETTINGS_CALLBACKS = {
  afterGet: {
    inventory: {
      general: afterGetSettingsInventoryGeneral,
      accounts: nullFunction,
      transactions: afterGetSettingsInventoryTransactions,
      barcode: afterGetSettingsInventoryBarcode,
    },
    fulfillment: {
      shipments: afterGetSettingsFulfillmentShipments,
      pickTickets: afterGetSettingsFulfillmentPickTickets,
    },
    production: {
      workOrders: afterGetSettingsProductionWorkOrders,
      builds: afterGetSettingsProductionBuilds,
      jobs: afterGetSettingsProductionJobs,
      processes: afterGetSettingsProductionProcesses,
    },
    purchasing: {
      general: nullFunction,
      purchaseOrders: afterGetSettingsPurchasingPurchaseOrders,
      itemReceipts: afterGetSettingsPurchasingItemReceipts,
      returnsToVendors: afterGetSettingsPurchasingReturnsToVendors,
    },
    rentals: {
      rentals: afterGetSettingsRentalsRentals,
      rentalReturns: afterGetSettingsRentalReturns,
    },
    sales: {
      customerPortal: nullFunction,
      general: nullFunction,
      salesOrders: afterGetSettingsSalesSalesOrders,
      estimates: afterGetSettingsSalesEstimates,
      invoices: afterGetSettingsSalesInvoices,
      salesReceipts: afterGetSettingsSalesSalesReceipts,
      rmas: afterGetSettingsSalesRmas,
      returns: afterGetSettingsSalesReturns,
    },
    user: { general: afterGetSettingsUserGeneral },
    company: {
      general: afterGetSettingsCompanyGeneral,
      accounting: afterGetSettingsAccounting,
      nameAndAddress: afterGetSettingsnameAndAddress,
      logo: nullFunction,
    },
    payments: { general: afterGetSettingsPaymentsGeneral },
    connections: { quickbooks: afterGetSettingsConnectionsQuickBooks },
  },
  beforeSave: {
    inventory: {
      general: beforeSaveSettingsInventoryGeneral,
      accounts: nullFunction,
      transactions: beforeSaveSettingsInventoryTransactions,
      barcode: beforeSaveSettingsInventoryBarcode,
    },
    fulfillment: {
      shipments: beforeSaveSettingsFulfillmentShipments,
      pickTickets: beforeSaveSettingsFulfillmentPickTickets,
    },
    production: {
      workOrders: beforeSaveSettingsProductionWorkOrders,
      builds: beforeSaveSettingsProductionBuilds,
      jobs: beforeSaveSettingsProductionJobs,
      processes: beforeSaveSettingsProductionProcesses,
    },
    purchasing: {
      general: nullFunction,
      purchaseOrders: beforeSaveSettingsPurchasingPurchaseOrders,
      itemReceipts: beforeSaveSettingsPurchasingItemReceipts,
      returnsToVendors: beforeSaveSettingsPurchasingReturnsToVendors,
    },
    rentals: {
      rentals: beforeSaveSettingsRentalsRentals,
      rentalReturns: beforeSaveSettingsRentalReturns,
    },
    sales: {
      customerPortal: nullFunction,
      general: nullFunction,
      salesOrders: beforeSaveSettingsSalesSalesOrders,
      estimates: beforeSaveSettingsSalesEstimates,
      invoices: beforeSaveSettingsSalesInvoices,
      salesReceipts: beforeSaveSettingsSalesSalesReceipts,
      rmas: beforeSaveSettingsSalesRmas,
      returns: beforeSaveSettingsSalesReturns,
    },
    user: { general: beforeSaveSettingsUserGeneral, password: nullFunction },
    company: {
      general: beforeSaveSettingsCompanyGeneral,
      accounting: beforeSaveSettingsAccounting,
      nameAndAddress: beforeSaveSettingsNameAndAddress,
      logo: nullFunction,
    },
    payments: { general: beforeSaveSettingsPaymentsGeneral },
    connections: { quickbooks: beforeSaveSettingsConnectionsQuickBooks },
  },
};

const API_PREFIX = SERVER + "/api/v2";

// There is no absolute length restriction on URLs, but many/most HTTP clients
// have an implementation maximum. Currently, Internet Explorer has the shortest
// limit, at 2048.
const URL_LENGTH_WARNING = 2048;
const MAX_ID_LENGTH_IN_CHARS = 10;
const MAX_IDS_IN_URL = Math.floor(
  URL_LENGTH_WARNING / (MAX_ID_LENGTH_IN_CHARS + 1)
);

// Universal return codes
export const SUCCESS = 0;
export const UNEXPECTED_ERROR = -1;

const UPDATE_COMPANY_URL = "/api/mobile/choosecompany";

// returns an object: {success: true/false, json: xxxxx}; json is only valid if
// success === true
async function doFetch(method, url, body, useRootUrl = false) {
  const bearerToken = getBearerToken();
  const submitUrl = (useRootUrl ? SERVER : API_PREFIX) + url;
  if (submitUrl.length > URL_LENGTH_WARNING) {
    handleProgramError(
      new Error(`doFetch | long URL (> ${URL_LENGTH_WARNING}): ${submitUrl}`)
    );
  }

  try {
    const response = await fetch(submitUrl, {
      method: method,
      headers: new Headers({
        Authorization: "Bearer " + bearerToken,
      }),
      body: body,
    });
    return response;
  } catch (e) {
    // this catches fetch fails; in particular, this can happen on long-
    // running report fetches, so we want to be able to handle those
    // explicitly
    if (e instanceof TypeError && e.message === FETCH_FAILURE) {
      return { systemError: true, message: e.message };
    }
    logError(e);
  }
}

export const FETCH_FAILURE = "Failed to fetch";
export async function jsonFetch(method, url, body, useRootUrl) {
  const response = await doFetch(method, url, body, useRootUrl);

  if (response.systemError) {
    return { success: false, message: FETCH_FAILURE };
  }

  let json;
  // response.ok if HTTP status was 200-299
  if (response.ok) {
    json = await response.json();
    return { success: true, status: response.status, json };
  } else {
    let message;
    switch (response.status) {
      case 401:
        json = await response.json();
        message = json.message || json.Message;
        logout();
        break;
      case 400:
      case 403:
      case 409:
        json = await response.json();
        message = json.message || json.Message;
        break;
      case 404:
        try {
          json = await response.json();
          message = json.statusText || json.message;
        } catch (e) {
          // response body is not valid json (empty or undefined)
          message = response.statusText;
        }
        break;
      case 500:
        json = await response.json();
        logError(
          new Error(
            `${method} : ${response.status}, ${response.statusText} : ${json.status}`
          )
        );
        message = json.message || i18n("global.SomethingWentWrong");
        break;
      default:
        message = i18n("global.SomethingWentWrong");
        break;
    }
    return { success: false, message: message };
  }
}

const ABORT_CONTROLLERS = {};
async function abortGetFetch(url, identifier) {
  const bearerToken = getBearerToken();
  const submitUrl = API_PREFIX + url;

  // set to empty array for first run
  if (!ABORT_CONTROLLERS[identifier]) {
    ABORT_CONTROLLERS[identifier] = [];
  }

  // cancel any outstanding fetches
  while (ABORT_CONTROLLERS[identifier].length) {
    const lpac = ABORT_CONTROLLERS[identifier].shift();
    lpac.abort();
  }

  // create a new AbortController for this fetch, and add it to the
  // array of AbortControllers
  const newAbortController = new AbortController();
  ABORT_CONTROLLERS[identifier].push(newAbortController);

  let response;
  try {
    response = await fetch(submitUrl, {
      method: "GET",
      headers: new Headers({ Authorization: "Bearer " + bearerToken }),
      signal: newAbortController.signal,
    });
    return response;
  } catch (err) {
    if (err.name !== "AbortError") {
      logError(new Error(`abortGetFetch : ${url}  :  ${err.name}`));
    } else {
      return undefined;
    }
  }
}

async function blobFetch(url, body, method) {
  const response = await doFetch(
    method,
    url,
    method === "POST" || method === "PUT" ? body : null
  );
  if (response.ok) {
    const blob = await response.blob();
    return { success: true, blob };
  } else {
    logError(new Error(`${method} blob : error in doFetch`));
    return { success: false };
  }
}

async function imageFetch(url) {
  const response = await doFetch("GET", url);
  const imageBlob = await response.blob();
  if (response.ok) {
    return { success: true, imageBlob };
  } else {
    logError(new Error("GET image : error in doFetch GET"));
    return { success: false };
  }
}

export async function getDocument(documentId) {
  const url = `/document/${documentId}/download`;
  const body = JSON.stringify({});
  const response = await blobFetch(url, body, "GET");
  return response.blob;
}

export async function getTemplate(objectType, templateId) {
  const url = `/${objectType}/${templateId}/download`;
  const body = JSON.stringify({});
  const { success, blob } = await blobFetch(url, body, "GET");
  if (success) {
    return blob;
  } else {
    logError(new Error("GET template document: error in blobFetch GET"));
  }
}

export async function getSupportPrivileges() {
  const url = "/support/privileges";
  const { success, json } = await jsonFetch("GET", url);
  if (success) {
    return CALLBACKS.afterGet.supportprivileges(json.data);
  } else {
    logError(new Error("GET support privileges : error in doFetch GET"));
  }
}

export async function copyTemplatesToAccount(ids) {
  const url = `/formTemplate/copyFromLibrary`;
  const body = JSON.stringify({ ids });
  const response = await jsonFetch("POST", url, body);
  return response;
}

export async function getPaymentPdf(paymentId) {
  const url = `/company/payment/${paymentId}?format=pdf`;
  const response = await blobFetch(url, null, "GET");
  return response.blob;
}

export const POST_DOCUMENT_DUPLICATE_FILENAME =
  "You already have a file with that filename. Please change the filename and try again.";

export async function postDocument(document) {
  const url = `/document`;
  const body = JSON.stringify(document);
  const { success, message } = await jsonFetch("POST", url, body);

  if (success) {
    return SUCCESS;
  } else {
    if (message === POST_DOCUMENT_DUPLICATE_FILENAME) {
      return POST_DOCUMENT_DUPLICATE_FILENAME;
    }
    return UNEXPECTED_ERROR;
  }
}

export async function getDocuments(query) {
  const url = `/document/quicklist?query=${query}`;
  const response = await jsonFetch("GET", url, null);
  const { success, json } = response;
  if (success) {
    return json.data;
  } else {
    logError(new Error("GET document links : error in doFetch GET"));
  }
}

export async function linkDocument(objectType, id, documentId) {
  const url = `/document/${documentId}/link`;
  const body = JSON.stringify([{ id, type: objectType }]);
  const { json, success } = await jsonFetch("POST", url, body);
  if (success) {
    return json.data;
  } else {
    logError(new Error("POST link document : error in doFetch POST"));
  }
}

export async function getLinkedDocuments(objectType, id) {
  const url = `/document/getbylink?type=${objectType}&id=${id}`;
  const response = await jsonFetch("GET", url, null);
  const { success, json } = response;
  if (success) {
    return json.data;
  } else {
    logError(new Error("GET document links : error in doFetch GET"));
  }
}

export async function deleteDocumentLink(objectType, id, documentId) {
  const url = `/document/${documentId}/link`;
  const body = JSON.stringify([{ type: objectType, id }]);
  const response = await jsonFetch("DELETE", url, body);
  const { success, json } = response;
  if (success) {
    return json.data;
  } else {
    logError(new Error("DELETE document link : error in doFetch DELETE"));
  }
}

export async function get(objectType, filters = {}) {
  const filterString = objectToQueryParams(filters);
  const filterParams = filterString ? `?${filterString}` : "";
  const url = `/${objectType}${filterParams}`;
  const response = await jsonFetch("GET", url, null);
  if (response.success) {
    return {
      success: true,
      data: {
        count: response.json.count,
        totalCount: response.json.totalCount,
        records: response.json.data.map((record) =>
          CALLBACKS.afterGet[objectType](record)
        ),
      },
    };
  } else {
    return { success: false, message: response.message };
  }
}

export async function getPaged(objectType, start, maxResults) {
  if (maxResults > LIST_MAX_MAX_RESULTS) {
    handleProgramError(
      new Error`maxResults parameter of ${maxResults} is too high; must be <= ${LIST_MAX_MAX_RESULTS}`()
    );
  }
  return await get(objectType, { start, maxResults });
}

export async function getPaymentHistory() {
  const url = "/company/payment";
  const { success, message, json } = await jsonFetch("GET", url, null);
  if (success) {
    return {
      success: true,
      data: json.data.map((record) =>
        CALLBACKS.afterGet.paymentHistory(record)
      ),
    };
  } else {
    return { success: false, message };
  }
}

export async function toggleAnnualBilling(annualPlan) {
  const url = "/company/billingPeriod";
  const body = JSON.stringify({ annualPlan });
  const { success, message } = await jsonFetch("PUT", url, body);
  return { success, message };
}

export async function updatePaymentMethod(record) {
  const url = "/company/paymentMethod";
  const body = JSON.stringify(record);
  const { success, message } = await jsonFetch("PUT", url, body);
  return { success, message };
}

export async function deletePaymentMethod(reason) {
  const url = "/company/paymentMethod";
  const body = JSON.stringify({ reason });
  const { success, message } = await jsonFetch("DELETE", url, body);
  return { success, message };
}

export async function getSettings() {
  const settingsResponse = await get("settings");
  if (settingsResponse.success) {
    return { success: true, data: settingsResponse.data.records[0] };
  } else {
    return settingsResponse;
  }
}

// note that this function never gets *all* columns; the columns parameter
// is either an array of calculated column names that should be returned
// in the results, or the NO_COLUMNS value, in which case no calculated
// columns will be returned
export async function getByIds(
  objectType,
  ids,
  columns = [NO_COLUMNS],
  filters = {}
) {
  let data = [];
  const filterString = objectToQueryParams(filters);
  const filterParams = filterString ? `&${filterString}` : "";
  const idArrays = splitArrByAmt(ids, MAX_IDS_IN_URL);
  for (let i = 0; i < idArrays.length; i++) {
    const idParams = idArrays[i].filter((item) => item).join(",");
    const url = `/${objectType}?ids=${idParams}&columns=${columns.join(
      ","
    )}${filterParams}`;
    const response = await jsonFetch("GET", url, null);
    const { success, json } = response;
    if (success) {
      json.data = json.data.map((record) =>
        CALLBACKS.afterGet[objectType](record)
      );
      data = data.concat(json.data);
    }
  }
  return data;
}

export async function getListPageRecords(objectType, filters, endpointUrl) {
  const filterString = objectToQueryParams(filters);
  const filterParams = filterString ? `?${filterString}` : "";
  const url = `/${endpointUrl || objectType}${filterParams}`;

  const response = await abortGetFetch(url, `listPageRecords-${objectType}`);

  if (!response) {
    // response is undefined for aborted calls
    return undefined;
  }

  if (response?.ok) {
    let json = await response.json();
    json.data = json.data.map((record) =>
      CALLBACKS.afterGet[objectType](record)
    );
    return json;
  } else {
    logError(new Error(`GET getListPageRecords : ${response.status}`));
    return undefined;
  }
}

export async function getRecord(objectType, id, inTransaction = null) {
  const url = `/${objectType}/${id}${
    inTransaction === IN_TRANSACTION ? "?inTransaction=true" : ""
  }`;
  const response = await jsonFetch("GET", url, null);
  const { success, json } = response;
  if (success) {
    return CALLBACKS.afterGet[objectType](json.data);
  } else {
    const message =
      response.message || i18n("error.RecordLoadError", { objectType });
    logError(new Error(`${objectType} : ${message}`));
  }
}

export async function getRecordWithParams(objectType, id, params) {
  const url = `/${objectType}/${id}?${objectToQueryParams(params)}`;
  const response = await jsonFetch("GET", url, null);
  const { success, json } = response;
  if (success) {
    return CALLBACKS.afterGet[objectType](json.data);
  } else {
    const message =
      response.message || i18n("error.RecordLoadError", { objectType });
    logError(new Error(`${objectType} : ${message}`));
  }
}

export async function getRecordFrom(objectType, fromType, fromId, dropShip) {
  const url = `/${objectType}/populateFrom${fromType}/${fromId}${
    dropShip ? `?dropship=true` : ""
  }`;
  const response = await jsonFetch("GET", url, null);
  const { success, json } = response;
  if (success) {
    return CALLBACKS.afterGet[objectType](json.data);
  } else {
    const message =
      response.message || i18n("error.RecordLoadError", { objectType });
    logError(
      new Error(
        `GET getRecordFrom ${objectType}: populate from ${fromType}: ${message}`
      )
    );
  }
}

export async function getRecordFromLine(objectType, fromType, fromId) {
  const url = `/${objectType}/populateFrom${fromType}Line/${fromId}`;
  const response = await jsonFetch("GET", url, null);
  const { success, json } = response;
  if (success) {
    return CALLBACKS.afterGet[objectType](json.data);
  } else {
    const message =
      response.message || i18n("error.RecordLoadError", { objectType });
    logError(
      new Error(
        `GET getRecordFromLine: ${objectType} populate from ${fromType}:  ${message}`
      )
    );
  }
}

export async function disassemble(id) {
  const url = `/process/disassembleBuild/${id}`;
  const response = await jsonFetch("GET", url);
  const { success, json } = response;
  if (success) {
    return CALLBACKS.afterGet.process(json.data);
  } else {
    const message =
      response.message ||
      i18n("error.RecordLoadError", { objectType: "process" });
    logError(new Error(`GET Process disassemble: ${message}}`));
  }
}

export async function getCustomerData(objectType, customerId) {
  const url = `/${objectType}/customerData/${customerId}`;
  const response = await jsonFetch("GET", url);
  const { success, json } = response;
  if (success) {
    return json.data;
  } else {
    const message =
      response.message || i18n("error.RecordLoadError", objectType);
    logError(new Error(`GET ${objectType} data" : ${message}`));
  }
}

export async function getSubCustomer(parentId) {
  const url = `/customer/createSubCustomer/${parentId}`;
  const response = await jsonFetch("GET", url);
  const { success, json } = response;
  if (success) {
    return CALLBACKS.afterGet.customer(json.data);
  } else {
    const message = response.message || i18n("error.SubCustomerError");
    logError(new Error(`GET sub customer : ${message}`));
  }
}

export async function ediBatch(ids, action) {
  const url = `/shipment/${action}`;
  const body = JSON.stringify({ ids });
  const response = await jsonFetch("POST", url, body);
  return response;
}

export async function putRecord(objectType, record) {
  let updatedRecord = { ...record };
  if (CALLBACKS.beforeSave[objectType]) {
    updatedRecord = CALLBACKS.beforeSave[objectType](record);
  }
  const url = `/${objectType}/${record.id}`;
  const body = JSON.stringify(updatedRecord);
  const response = await jsonFetch("PUT", url, body);
  if (response.success) {
    return {
      success: true,
      record: CALLBACKS.afterGet[objectType](response.json.data),
    };
  } else {
    log.debug(new Error(`body: ${body}`));
    return { success: false, message: response.message };
  }
}

export async function postRecord(objectType, record, newFromId) {
  let updatedRecord = { ...record };
  if (CALLBACKS.beforeSave[objectType]) {
    updatedRecord = CALLBACKS.beforeSave[objectType](record);
  }
  const url = `/${objectType}${newFromId ? `?populatedFrom=${newFromId}` : ""}`;
  const body = JSON.stringify(updatedRecord);
  const response = await jsonFetch("POST", url, body);
  if (response.success) {
    return {
      success: true,
      record: CALLBACKS.afterGet[objectType](response.json.data),
    };
  } else {
    log.debug(new Error(`body: ${body}`));
    return { success: false, message: response.message };
  }
}

export const DELETE_RECORDS_LOT_HAS_ONHAND =
  "You cannot delete this lot because it still has active quantity.";
export const DELETE_RECORDS_LOT_USED_ON_TRANSACTIONS =
  "You cannot delete this lot because it is used on transactions.";
export async function deleteRecords(objectType, ids) {
  const url = `/${objectType}/batch`;
  const body = JSON.stringify({ ids: ids });
  const response = await jsonFetch("DELETE", url, body);
  const { success, message } = response;
  if (success) {
    return SUCCESS;
  } else {
    if (message === DELETE_RECORDS_LOT_HAS_ONHAND) {
      return DELETE_RECORDS_LOT_HAS_ONHAND;
    }
    if (message === DELETE_RECORDS_LOT_USED_ON_TRANSACTIONS) {
      return DELETE_RECORDS_LOT_USED_ON_TRANSACTIONS;
    }
    return UNEXPECTED_ERROR;
  }
}

export async function clearItemCache(ids) {
  const url = `/item/clearCache`;
  const body = JSON.stringify({ ids });
  const response = await jsonFetch("PUT", url, body);
  return response;
}

export async function mergeRecords(objectType, ids) {
  const sourceId = ids.shift();
  const url = `/${objectType}/${sourceId}/merge`;
  const body = JSON.stringify({ ids: ids });
  const response = await jsonFetch("PUT", url, body);
  return response;
}

export async function updateBatchRecords(objectType, data) {
  const url = `/${objectType}/batch`;
  const body = JSON.stringify(data);
  const response = await jsonFetch("PUT", url, body);
  return response;
}

export async function syncRecords(objectType, ids) {
  const url = `/${objectType}/resync`;
  const body = JSON.stringify({ ids });
  const response = await jsonFetch("POST", url, body);
  return response;
}

export async function removePaymentInfo(id) {
  const url = `/customer/${id}/removePaymentInfo`;
  const response = await jsonFetch("PUT", url);
  return response;
}

export async function voidUpsShipment(id) {
  const url = `/upsshipment/${id}/void`;
  const response = await jsonFetch("PUT", url);
  return response;
}

export async function refundPayment(data) {
  const url = `/fspayment/refund`;
  const body = JSON.stringify(data);
  const { success, json, message } = await jsonFetch("POST", url, body);
  if (success) {
    return { success: true, data: json.data };
  } else {
    return { success: false, message };
  }
}

export async function getMerchantTrackInfo() {
  const url = "/fspayment/merchantTrackInfo";
  const { success, json, message } = await jsonFetch("GET", url);
  if (success) {
    return { success: true, data: json.data };
  } else {
    return { success: false, message };
  }
}

export async function getPlans() {
  const url = "/plan";
  const { success, json, message } = await jsonFetch("GET", url);
  if (success) {
    return { success, data: json.data };
  } else {
    return { success: false, message };
  }
}

export async function updatePlan(data) {
  const url = "/company/plan";
  const body = JSON.stringify(data);
  const { success, json, message } = await jsonFetch("PUT", url, body);
  if (success) {
    return { success: true, data: json.data };
  } else {
    return { success: false, message };
  }
}

export async function getQuickList(objectType, filters) {
  const filterString = objectToQueryParams(filters);
  const filterParams = filterString ? `?${filterString}` : "";
  const url = `/${objectType}/quicklist${filterParams}`;
  const response = await jsonFetch("GET", url, null);
  const { success, json } = response;
  if (success) {
    return json;
  }
}

export async function exactNameMatch(objectType, nameString) {
  const url = `/${objectType}/quicklist?exactMatch=true&query=${nameString}&inTransaction=true`;
  const response = await jsonFetch("GET", url, null);
  const { success, json } = response;
  if (success) {
    return json.data[0];
  }
}

export async function getAllReference(objectType) {
  const url = `/${objectType}`;
  const response = await jsonFetch("GET", url, null);
  const { success, json } = response;
  if (success) {
    return json.data.map((record) => CALLBACKS.afterGet[objectType](record));
  }
}

export async function getItemByNameBarcodeSku(query) {
  const url = `/item/quicklist?nameBarcodeSku=${encodeURIComponent(
    query
  )}&inTransaction=true`;
  const response = await jsonFetch("GET", url, null);
  const { success, json } = response;
  if (success) {
    return json.data;
  }
}

export async function getDashboardSettings() {
  const url = "/dashboard/settings";
  const response = await jsonFetch("GET", url, null);
  const { success, json } = response;
  if (success) {
    return json.data;
  } else {
    logError(new Error("GET dashboard settings : error in jsonFetch GET"));
  }
}

export async function getBinContents(id) {
  const url = `/bins/${id}/contents`;
  const response = await jsonFetch("GET", url, null);
  const { success, json } = response;
  if (success) {
    return json.data;
  } else {
    logError(new Error("GET bin contents : error in jsonFetch GET"));
  }
}

export async function updateDashboardSettings(data) {
  const url = "/dashboard/settings";
  const body = JSON.stringify(data);
  const response = await jsonFetch("PUT", url, body);
  const { success, json } = response;
  if (success) {
    return json.data;
  } else {
    logError(new Error("PUT dashboard settings failed"));
  }
}

// note that the back end honors the calendar settings when returning
// the data; only currently selected calendar entries will be returned
export async function getCalendarData(startDate, endDate) {
  const url = `/calendar?startDate=${dateToSosISODate(
    startDate
  )}&endDate=${dateToSosISODate(endDate)}`;

  const response = await abortGetFetch(url, "calendarStartDate");

  if (!response) {
    // response is undefined for aborted calls
    return undefined;
  }

  if (response.ok) {
    const { data } = await response.json();
    return { success: true, data };
  } else {
    logError(new Error(`GET getCalendarData : ${response.status}`));
    return undefined;
  }
}

export async function getCalendarSettings() {
  const url = "/calendar/settings";
  const response = await jsonFetch("GET", url);
  const { success, json } = response;
  if (success) {
    return { success: true, data: json.data };
  } else {
    logError(new Error("GET dashboard settings failed"));
    return { success: false };
  }
}

export async function updateCalendarSettings(data) {
  const url = "/calendar/settings";
  const body = JSON.stringify(data);
  const response = await jsonFetch("PUT", url, body);
  const { success } = response;
  if (success) {
    return true;
  } else {
    logError(new Error("PUT dashboard settings : error in jsonFetch PUT"));
    return false;
  }
}

export async function getOpenPurchaseOrdersByVendor(vendorId) {
  const url = `/purchaseorder?vendorId=${vendorId}&status=open&inTransaction=true`;
  const response = await jsonFetch("GET", url, null);
  const { success, json } = response;
  if (success) {
    return json;
  }
}

export async function getSerialsAvailable(itemId, locationId, binId) {
  const binParam = binId ? `&binId=${binId}` : "";
  const url = `/serial/quicklist?itemId=${itemId}&locationId=${locationId}${binParam}`;
  const response = await jsonFetch("GET", url);
  const { success, json } = response;
  if (success) {
    return json.data;
  }
}

export async function getReturnableSerials(itemId, customerId) {
  const url = `/serial/returnable?itemId=${itemId}&customerId=${customerId}`;
  const response = await jsonFetch("GET", url);
  const { success, json } = response;
  if (success) {
    return json.data;
  }
}

export async function getExchangeRate(currencyCode, asOfDate) {
  const url = `/exchangeRate?currencyCode=${currencyCode}&date=${format(
    asOfDate,
    "MM/dd/yyyy"
  )}`;
  const response = await jsonFetch("GET", url, null);
  const { success, json } = response;
  if (success) {
    if (!json.data) {
      return null;
    }
    return json.data[0];
  }
}

export async function getItemLocationSettings(itemId, locationId = null) {
  const url = `/item/${itemId}/locationSettings`;
  const response = await jsonFetch("GET", url, null);
  const { success, json } = response;
  if (success) {
    if (locationId) {
      return {
        success: true,
        data: json.data.find((l) => l.location.id === locationId),
      };
    } else {
      return { success: true, data: json.data };
    }
  } else {
    return { success: false };
  }
}

export async function saveItemLocationSettings(itemId, locations) {
  const url = `/item/${itemId}/locationSettings`;
  const body = JSON.stringify({ data: locations });
  const response = await jsonFetch("PUT", url, body);
  const { success, json } = response;
  if (success) {
    return { success: true, data: json.data };
  } else {
    return { success: false };
  }
}

export async function getVendorItemInfo(vendorId, itemIds) {
  const url = `/vendoritem?itemIds=${itemIds}&vendorId=${vendorId}&inTransaction=true`;
  const response = await jsonFetch("GET", url, null);
  const { success, json } = response;
  if (success) {
    return json.data.map((record) => CALLBACKS.afterGet.vendoritem(record));
  } else {
    return null;
  }
}

export async function getTaxCodes(taxCodeType) {
  const optionsData = await getAllReference("taxcode");
  if (taxCodeType === "all") {
    return optionsData;
  }
  const filteredOptionsData = optionsData.filter((option) =>
    taxCodeType === "purchase"
      ? option.isPurchaseTaxType
      : option.isSalesTaxType
  );
  return filteredOptionsData;
}

export async function getLotsByItemLocationBinDate(
  itemId,
  locationName,
  binId,
  dateTime
) {
  const sosIsoDateTime = dateTime ? dateToSosISODateTime(dateTime) : "";
  const binParam = binId ? `&binId=${binId}` : "";
  const url = `/lot?itemId=${itemId}&location=${locationName}&dt=${sosIsoDateTime}${binParam}`;
  const response = await jsonFetch("GET", url, null);
  const { success, json } = response;
  if (success) {
    return json.data.map((record) => CALLBACKS.afterGet.lot(record));
  } else {
    return null;
  }
}

export async function getTransctionPdfs(objectType, ids, templateId) {
  const url = "/report/transaction";
  const body = JSON.stringify({
    action: "pdf",
    // update to use typeString values once
    // https://app.clickup.com/t/8689a073c complete
    type: TEMPLATE_NAMES[objectType],
    templateId: templateId,
    txnIds: ids,
  });
  const response = await blobFetch(url, body, "POST");
  return response.blob;
}

export async function getPrintLabelPdfs(ids, templateId) {
  const url = "/upsshipment/batch";
  const body = JSON.stringify({
    ids,
    templateId,
    action: "printLabel",
  });
  const response = await blobFetch(url, body, "PUT");
  return response.blob;
}

export async function contactUs(data) {
  const url = `/contactus`;
  const body = JSON.stringify(data);
  const response = await jsonFetch("POST", url, body);
  const { success, json, message } = response;
  if (success) {
    return { success: true, data: json.data };
  } else {
    return { success: false, message };
  }
}

export async function getUserListSettings(userId, objectType, dateFormat) {
  const url = `/user/${userId}/listSettings/${objectType}`;
  const response = await jsonFetch("GET", url, null);
  const { success, json, status } = response;
  if (success) {
    if (status === 404) {
      return null;
    }
    const transformedRecords = CALLBACKS.afterGet.listSettings(
      json.data,
      dateFormat
    );
    return transformedRecords;
  }
}

export async function putUserListSettings(userId, objectType, settings) {
  const url = `/user/${userId}/listSettings/${objectType}`;
  const body = JSON.stringify(settings);
  const response = await jsonFetch("PUT", url, body);
  if (response.success) {
    return true;
  } else {
    log.debug(new Error(`body: ${body}`));
    return false;
  }
}

export async function updateDefaultCompany(id) {
  const body = JSON.stringify({
    defaultCompanyId: id,
  });
  const response = await doFetch("PUT", UPDATE_COMPANY_URL, body, true);
  if (!response.ok) {
    logError(new Error("Error updating default company"));
  }
}

export async function getItemBom(itemId) {
  const url = `/item/${itemId}/bom`;
  const response = await jsonFetch("GET", url, null);
  const { success, json } = response;
  if (success) {
    return json.data.lines.map((record) => CALLBACKS.afterGet.bom(record));
  }
}

export async function getList(objectType, filters) {
  const filterString = objectToQueryParams(filters);
  const filterParams = filterString ? `?${filterString}` : "";
  const url = `/${objectType}${filterParams}`;
  const response = await jsonFetch("GET", url, null);
  const { success, json } = response;
  if (success) {
    return json.data.map((record) => CALLBACKS.afterGet[objectType](record));
  }
}

export async function saveItemBom(itemId, record) {
  const url = `/item/${itemId}/bom`;
  const updatedRecord = CALLBACKS.beforeSave.bom(record);
  const body = JSON.stringify(updatedRecord);
  const response = await jsonFetch("POST", url, body);
  const { success, json } = response;
  if (success) {
    return { success: true, data: json.data };
  } else {
    return { success: false };
  }
}

export async function getBoxes(id) {
  const url = `/item/${id}/box`;
  const { success, json } = await jsonFetch("GET", url);
  if (success) {
    return CALLBACKS.afterGet.box(json.data);
  } else {
    logError(new Error("GET box : error in doFetch GET"));
  }
}

export async function saveBoxes(id, record) {
  const url = `/item/${id}/box`;
  const updatedRecord = CALLBACKS.beforeSave.box(record);
  const body = JSON.stringify(updatedRecord);
  const { success, json } = await jsonFetch("POST", url, body);
  if (success) {
    return { success: true, data: json.data };
  } else {
    return { success: false };
  }
}

export async function workOrderproduceAll(id) {
  const url = `/workorder/${id}/produceAll`;
  const { success, json } = await jsonFetch("POST", url);
  if (success) {
    return { success: true, data: json.data };
  } else {
    return { success: false };
  }
}

export async function testAlert(id) {
  const url = `/alert/${id}/test`;
  const { success, json } = await jsonFetch("POST", url);
  if (success) {
    return { success: true, data: json.data };
  } else {
    return { success: false };
  }
}

export async function restoreDefaultTemplates() {
  const url = "/formtemplate/restoreDefaults";
  const { success, json } = await jsonFetch("PUT", url);
  if (success) {
    return { success: true, data: json.data };
  } else {
    return { success: false };
  }
}

// see AccountingAddOn/api/ItemApi.cs for valid filters;
//
// for all lists, we impose a limit of ITEM_MAX_RESULTS, because we don't
// want to retrieve potentially thousands of results, and these lists are
// typically used in a select field where we include dynamic in-field search
// functionality; however, for categories, we do not limit the results (by
// setting maxresults to 0), as categories are sometimes used outside of this
// select field context (and it's unlikely that a customer has a large number
// of categories that would cause a performance issue)
export async function getItemQuicklist(filters = {}) {
  const isCategoryRequest =
    typeof filters.type === "string" &&
    filters.type?.toLowerCase() === ITEM_QUICKLIST_CASES.CATEGORY.toLowerCase();
  const filterParams = objectToQueryParams({
    ...filters,
    maxresults: isCategoryRequest ? 0 : ITEM_MAX_RESULTS,
    inTransaction: true,
  });
  const url = `/item/quicklist?${filterParams}`;
  const response = await jsonFetch("GET", url);
  const { success, json } = response;
  if (success) {
    return json;
  } else {
    logError(new Error("GET getItemQuicklist : error in jsonFetch GET"));
  }
}

export async function getAuditHistory(objectType, id) {
  const { typeString } = getObjectFromFullString(objectType);
  const url = `/audit/history?type=${typeString}&id=${id}`;
  const response = await jsonFetch("GET", url, null);
  const { success, json } = response;
  if (success) {
    const transformedRecords = json.data.map((record) =>
      CALLBACKS.afterGet.auditHistory(record)
    );
    return transformedRecords;
  }
}

export async function getHistory(objectType, id, params = {}) {
  const filterString = objectToQueryParams(params);
  const filterParams = filterString ? `?${filterString}` : "";
  const url = `/${objectType}/${id}/transactionHistory${filterParams}`;
  const response = await jsonFetch("GET", url);
  const { success, json } = response;
  if (success) {
    const afterGetType = `${objectType}History`;
    json.data = json.data.map((record) =>
      CALLBACKS.afterGet[afterGetType](record)
    );
    return json;
  }
}

export async function getInventory(id, type, relatedObjectType) {
  const url = `/item/${id}/itemson${relatedObjectType}`;
  const response = await jsonFetch("GET", url);
  const { success, json } = response;
  if (success) {
    json.data = json.data.map((record) =>
      CALLBACKS.afterGet.itemInventory(record, type)
    );
    return json;
  } else {
    return { success: false, message: json.message };
  }
}

export async function getFtpConnections(inReport) {
  const url = `/ftpconnection?inReport=${inReport === IN_REPORT}`;
  const response = await jsonFetch("GET", url, null);
  if (response.success) {
    return {
      success: true,
      data: {
        count: response.json.count,
        totalCount: response.json.totalCount,
        records: response.json.data.map((record) =>
          CALLBACKS.afterGet.ftpconnection(record)
        ),
      },
    };
  } else {
    return { success: false, message: response.message };
  }
}

export async function getRentalHistory(objectType, id, params = {}) {
  const filterString = objectToQueryParams(params);
  const filterParams = filterString ? `?${filterString}` : "";
  const url = `/${objectType}/${id}/rentalHistory${filterParams}`;
  const response = await jsonFetch("GET", url);
  const { success, json } = response;
  if (success) {
    json.data = json.data.map((record) =>
      CALLBACKS.afterGet.rentalHistory(record)
    );
    return json;
  }
}

export async function getProfitLoss(objectType, id, params = {}) {
  const filterString = objectToQueryParams(params);
  const filterParams = filterString ? `?${filterString}` : "";
  const url = `/${objectType}/${id}/profitLossReport${filterParams}`;
  const response = await jsonFetch("GET", url);
  const { success, json } = response;
  if (success) {
    const afterGetType = `${objectType}ProfitLoss`;
    return CALLBACKS.afterGet[afterGetType](json.data);
  }
}

export async function createInvoicesFromSalesOrders(ids) {
  const url = "/invoice/createFromSalesOrders";
  const body = JSON.stringify({ ids });
  const response = await jsonFetch("POST", url, body);
  const { success, json } = response;
  if (success) {
    return { success: true };
  } else {
    return { success: false, message: json.message };
  }
}

export async function getJobDashboard(id) {
  const url = `/job/${id}/dashboard`;
  const response = await jsonFetch("GET", url, null);
  const { success, json } = response;
  if (success) {
    return json.data.map((record) => CALLBACKS.afterGet.jobDashboard(record));
  }
}

export async function setStarred(objectType, objectId, value) {
  const url = `/${objectType}/${objectId}/starred`;
  const body = JSON.stringify({ value });
  const response = await jsonFetch("PUT", url, body);
  const { success } = response;
  if (success) {
    return true;
  }
}

export async function calculateDueDate(termsId, transactionDate) {
  const url = `/util/calculateDueDate?termId=${termsId}&transactionDate=${format(
    transactionDate,
    "MM/dd/yyyy"
  )}`;
  const response = await jsonFetch("GET", url);
  const { success, json } = response;
  if (success) {
    return json.data;
  }
}

export async function getBarcodeSample(toBeEncoded, format, width, height) {
  const url = `/barcodeSample?toBeEncoded=${encodeURIComponent(
    toBeEncoded
  )}&format=${encodeURIComponent(format)}&width=${encodeURIComponent(
    width
  )}&height=${encodeURIComponent(height)}`;
  const { success, imageBlob } = await imageFetch(url);
  if (success) {
    return imageBlob;
  }
}

export async function getBarcodeImageBlob(code) {
  const url = `/barcode?${objectToQueryParams({ code })}`;
  const { success, imageBlob } = await imageFetch(url);
  if (success) {
    return imageBlob;
  }
}

export async function getItemUom(id) {
  const url = `/item/${id}/uomSet`;
  const { success, json } = await jsonFetch("GET", url);
  if (success) {
    return json.data.map((record) => CALLBACKS.afterGet.uomSet(record));
  } else {
    logError(new Error("GET Item UOM : error in doFetch GET"));
  }
}

export async function globalSearch(query) {
  const url = `/api/mobile/smartsearch?terms=${query}&IsWebAppRequest=true`;
  const { success, json } = await jsonFetch("GET", url, undefined, true);
  if (success) {
    return json.data;
  } else {
    logError(new Error("GET smart search : error in smart search GET"));
  }
}

export async function getRecentTransactions() {
  const url = "/audit/history/recent";
  const { success, json } = await jsonFetch("GET", url);
  if (success) {
    return json.data.map((record) => CALLBACKS.afterGet.recentHistory(record));
  } else {
    logError(new Error("error in getRecentTransactions"));
  }
}

export async function saveItemUom(id, uoms) {
  const updatedUoms = CALLBACKS.beforeSave.uomSet(uoms);
  const body = JSON.stringify(updatedUoms);
  const url = `/item/${id}/uomSet`;
  const { success, json } = await jsonFetch("POST", url, body);
  if (success) {
    return { success: true, data: json.data };
  } else {
    return { success: false };
  }
}

export async function saveCustomReport(settings) {
  const body = JSON.stringify(settings);
  const url = "/customreport";
  const { success, json } = await jsonFetch("POST", url, body);
  if (success) {
    return { success: true, data: json.data };
  } else {
    return { success: false };
  }
}

export async function getReportFavorites() {
  const url = "/report/favorite";
  const { success, json, message } = await jsonFetch("GET", url);
  if (success) {
    return json.data;
  } else {
    logError(new Error(message));
  }
}

export async function favoriteReport(reportType, customReportId = null) {
  const body = JSON.stringify({ type: reportType, customReportId });
  const url = "/report/favorite";
  const { success, json, message } = await jsonFetch("POST", url, body);
  if (success) {
    return { success: true, data: json.data };
  } else {
    return { success: false, message };
  }
}

export async function unfavoriteReport(favoriteId) {
  const url = `/report/favorite/${favoriteId}`;
  const { success, message } = await jsonFetch("DELETE", url);
  if (success) {
    return { success: true };
  } else {
    return { success: false, message };
  }
}

export async function deleteRecord(objectType, id) {
  const url = `/${objectType}/${id}`;
  const { success, message } = await jsonFetch("DELETE", url);
  if (success) {
    return { success: true };
  } else {
    return { success: false, message };
  }
}

export async function getVariants(id) {
  const url = `/item/${id}/variant`;
  const { success, json } = await jsonFetch("GET", url);
  if (success) {
    return CALLBACKS.afterGet.variant(json.data);
  } else {
    logError("GET variant : error in doFetch GET");
  }
}
export const VARIANT_TYPE_MISMATCH = "Incompatible item -- type mismatch";
export const VARIANT_OF_ANOTHER_ITEM =
  "Incompatible item -- variant of another item";
export async function saveVariants(id, record) {
  const body = JSON.stringify(record);
  const url = `/item/${id}/variant`;
  const { success, json, message } = await jsonFetch("POST", url, body);
  if (success) {
    return { success: true, data: json.data };
  } else {
    if (message === VARIANT_TYPE_MISMATCH) {
      return { success: false, message: VARIANT_TYPE_MISMATCH };
    }
    if (message === VARIANT_OF_ANOTHER_ITEM) {
      return { success: false, message: VARIANT_OF_ANOTHER_ITEM };
    }
    return { success: false, message };
  }
}

export async function saveContactAddresses(id, type, addresses) {
  const updatedAddresses = CALLBACKS.beforeSave.address(addresses);
  const body = JSON.stringify(updatedAddresses);
  const url = `/${type}/${id}/address`;
  const { success, json } = await jsonFetch("POST", url, body);
  if (success) {
    return { success: true, data: json.data };
  } else {
    return { success: false };
  }
}

export async function getContactAddresses(id, type) {
  const url = `/${type}/${id}/address`;
  const { success, json } = await jsonFetch("GET", url);
  if (success) {
    return json.data.map((record) => CALLBACKS.afterGet.address(record));
  } else {
    logError(new Error(`GET ${type} address : error in doFetch GET`));
  }
}

export async function sendTransactionEmail(
  objectType,
  txnIds,
  templateId,
  to,
  subject,
  message,
  attachments,
  copyMe
) {
  const url = "/report/transaction";
  const body = {
    action: "sendEmail",
    // update to use typeString values once
    // https://app.clickup.com/t/8689a073c complete
    type: TEMPLATE_NAMES[objectType],
    body: message,
    templateId,
    txnIds,
    to,
    subject,
    copyMe,
  };
  if (attachments) {
    body.attachments = attachments;
  }
  const jsonBody = JSON.stringify(body);
  return await jsonFetch("POST", url, jsonBody);
}

export async function calculateSalesCostBasis(itemId, quantity, dateTime) {
  const localDateTime = dateTime ? dateToSosISODateTime(dateTime) : "";
  const url = `/item/${itemId}/calculateSalesCostBasis?qty=${quantity}&dt=${localDateTime}`;
  const response = await jsonFetch("GET", url);
  const { success, json } = response;
  if (success) {
    return afterGetCostBasis(json.data);
  }
}

export async function calculateBomCostBasis(itemId) {
  const url = `/item/${itemId}/calculateBomCostBasis`;
  const response = await jsonFetch("GET", url);
  const { success, json } = response;
  if (success) {
    return afterGetCostBasis(json.data);
  }
}

export async function addToReorderList(itemIds) {
  const response = await jsonFetch(
    "POST",
    "/reorder/item",
    JSON.stringify(itemIds)
  );
  return response.success ? SUCCESS : UNEXPECTED_ERROR;
}

export async function generatePos(params) {
  const response = await jsonFetch(
    "POST",
    "/purchaseorder/generatePO",
    JSON.stringify(params)
  );
  return response.success ? SUCCESS : UNEXPECTED_ERROR;
}

export async function generatePurchaseOrdersFromReorders(ids, date) {
  const response = await jsonFetch(
    "POST",
    "/reorder/generatePurchaseOrders",
    JSON.stringify({ ids, date })
  );
  return response.success ? SUCCESS : UNEXPECTED_ERROR;
}

export async function calculateAdjustmentCostBasis(itemId, quantity, dateTime) {
  const localDateTime = dateTime ? dateToSosISODateTime(dateTime) : "";
  const url = `/item/${itemId}/calculateAdjustmentCostBasis?qty=${quantity}&dt=${localDateTime}`;
  const response = await jsonFetch("GET", url);
  const { success, json } = response;
  if (success) {
    return afterGetCostBasis(json.data);
  }
}

export async function getCompanyUserInfo() {
  const response = await jsonFetch("GET", "/settings/companyUserInfo");
  if (response.success) {
    return {
      success: true,
      record: CALLBACKS.afterGet.companyUserInfo(response.json.data),
    };
  } else {
    return { success: false, message: response.message };
  }
}

export async function getSignature(objectType, id) {
  const url = `/${objectType}/${id}?action=signature`;
  const response = await imageFetch(url);
  const { success, imageBlob } = response;
  if (success) {
    return imageBlob;
  } else {
    logError(new Error("GET signature : error in doFetch GET"));
  }
}

export const REPORT_TOO_LARGE_TO_DISPLAY =
  "This report is too large to display. Change the report settings or send it via email.";
export const REPORT_TOO_LARGE =
  "This report is too large. Change the report settings to reduce the size.";
export async function getReport(
  type,
  action,
  format,
  email,
  settings,
  isJsonReport,
  columns
) {
  const bodyObject = {
    type,
    action,
    format,
    email,
    settings,
    columns,
  };
  const body = JSON.stringify(bodyObject);
  const url = "/report";
  let response;
  switch (action) {
    case "Display":
      try {
        response = await jsonFetch("POST", url, body);
        if (response.success) {
          const { thead, tbody, tfoot, data } = response.json;
          if (isJsonReport) {
            return {
              success: true,
              data: data.map((record) =>
                CALLBACKS.afterGet.report(record, type)
              ),
            };
          }
          return {
            success: true,
            thead,
            tbody,
            tfoot,
          };
        }
        if (response.message === REPORT_TOO_LARGE) {
          return { success: false, message: REPORT_TOO_LARGE };
        }
        if (response.message === REPORT_TOO_LARGE_TO_DISPLAY) {
          return { success: false, message: REPORT_TOO_LARGE_TO_DISPLAY };
        }
        if (response.message === FETCH_FAILURE) {
          return { success: false, message: FETCH_FAILURE };
        }
      } catch (e) {
        return { success: false, message: e.message };
      }
      break;
    case "Email":
      try {
        response = await jsonFetch("POST", url, body);
        if (response.success) {
          return { success: true };
        }
        return { success: false, message: response.message };
      } catch (e) {
        return { success: false, message: e.message };
      }
    case "Download":
      try {
        response = await blobFetch(url, body, "POST");
        if (response.success) {
          return response.blob;
        }
        return { success: false, message: response.message };
      } catch (e) {
        return { success: false, message: e.message };
      }
    default:
      logError(new Error(`unexpected value for action: ${action}`));
  }
  return { success: false };
}

export async function getScheduleForStandardReport(reportType) {
  const url = `/schedule?standardReportType=${reportType}`;
  const response = await jsonFetch("GET", url, null);
  if (response.success) {
    return response.json.data[0];
  } else {
    if (response.message === "Could not find requested object.") {
      return undefined;
    }
    logError(new Error("getScheduleForStandardReport : error on GET"));
  }
}

export async function saveAsNewCustomReport(type, name, settings, columns) {
  const bodyObject = {
    type,
    name,
    settings,
    columns,
  };
  const body = JSON.stringify(bodyObject);
  const url = "/customreport";
  const response = await jsonFetch("POST", url, body);
  if (response.success) {
    return { success: true, report: response.json.data };
  }
  return { success: false };
}

export async function scheduleReport(type, customReport, schedule) {
  const bodyObject = {
    type,
    customReport,
    frequency: schedule.frequency,
    timeOfDay: schedule.timeOfDay,
    dayOfWeek: schedule.dayOfWeek,
    email: schedule.email,
    ftp: schedule.ftp,
  };
  const body = JSON.stringify(bodyObject);
  const url = "/schedule";
  const response = await jsonFetch("POST", url, body);
  if (response.success) {
    return { success: true, report: response.json.data };
  }
  return { success: false };
}

export async function unscheduleReport(id) {
  const url = `/schedule/${id}`;
  const response = await jsonFetch("DELETE", url, null);
  const { success } = response;
  if (success) {
    return true;
  } else {
    logError(new Error("DELETE report schedule : error in doFetch DELETE"));
    return false;
  }
}

export async function updateCustomReport(id, type, name, settings, columns) {
  const bodyObject = {
    type,
    name,
    settings,
    columns,
  };
  const body = JSON.stringify(bodyObject);
  const url = `/customreport/${id}`;
  let response;
  response = await jsonFetch("PUT", url, body);
  if (response.success) {
    return { success: true, report: response.json.data };
  }
  return { success: false };
}

export async function deleteCustomReport(id) {
  const url = `/customreport/${id}`;
  const response = await jsonFetch("DELETE", url, null);
  const { success } = response;
  if (success) {
    return true;
  } else {
    logError(new Error("DELETE custom report : error in doFetch DELETE"));
    return false;
  }
}

export async function runReportImmediate(
  type,
  customReport,
  settings,
  columns,
  frequency,
  timeOfDay,
  dayOfWeek,
  email,
  ftp
) {
  const bodyObject = {
    type,
    customReport,
    settings,
    columns,
    frequency,
    timeOfDay,
    dayOfWeek,
    email,
    ftp,
  };
  const body = JSON.stringify(bodyObject);
  const url = "/schedule/test";
  const response = await jsonFetch("POST", url, body);
  return response.success;
}

export async function submitFsPaymentInfoRequest(formFields) {
  const body = JSON.stringify(formFields);
  const url = "/fspayment/sosPaymentsInfoRequest";
  const response = await jsonFetch("POST", url, body);
  return response.success;
}

export async function getFsAuthKey() {
  const url = "/fspayment/authkey";
  const response = await jsonFetch("GET", url);
  const { success, json } = response;
  if (success) {
    return json.data;
  } else {
    logError(new Error("GET FS auth key : error in jsonFetch GET"));
    return false;
  }
}

export async function logHostedPaymentsResult(params) {
  const { id, result, token } = params;
  const url = "/fspayment/sosHostedPaymentsLog";
  // body does not match our standard JSON.stringify usage because
  // this sosHostedPaymentsLog endpoint is taking this request data
  // and directly logging it
  const body = `{'token':'${token}', 'id':'${id}', 'result':'${result}'}`;
  const { success, json } = await jsonFetch("POST", url, body);
  if (success) {
    return json.data;
  } else {
    logError(
      new Error("POST hosted payments result : error in jsonFetch POST")
    );
    return false;
  }
}

// get any singleton record; pass the resource name (can be one level, like
// "setting", or multi-level, like "setting/brightness"); returns the re-
// quested data, or false
export async function getSingleton(resource) {
  const response = await jsonFetch("GET", `/${resource}`);
  const { success, json } = response;
  if (success) {
    return CALLBACKS.afterGet[resource](json.data);
  } else {
    logError(new Error(`getSingleton | error getting ${resource}`));
    return false;
  }
}

export async function moveSyncItemToEnd(id) {
  const url = `/syncitem/${id}/moveToEnd`;
  const { success } = await jsonFetch("PUT", url);
  return success;
}

export async function getSettingsValues(level1, level2) {
  const url = `/configurationSettings/${level1}/${level2}`;
  const response = await jsonFetch("GET", url, null);
  const { success, json } = response;
  if (success) {
    return SETTINGS_CALLBACKS.afterGet[level1][level2](json.data);
  } else {
    logError(new Error("getSettingsValues : error in jsonFetch GET"));
    return false;
  }
}

export async function updateSettingsValues(level1, level2, settings) {
  const url = `/configurationSettings/${level1}/${level2}`;
  const body = JSON.stringify(
    SETTINGS_CALLBACKS.beforeSave[level1][level2](settings)
  );
  const { success, message } = await jsonFetch("PUT", url, body);
  return success ? { success } : { success, message };
}

function logError(error) {
  handleProgramError(error);
  throw error;
}
